import './expert-details.scss';
import './ExpertDetailsResponsive.scss';
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { connect } from 'react-redux';
import CategoryCard from '../../components/CategoryCard/CategoryCard';
import SessionCard from '../../components/SessionCard/SessionCard';
import { LanguageTag } from '../Session/Session';
import { faFacebookF, faInstagram, faTwitter, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { getExpertProfile } from './../../service/Api/ApiRequest';
import { useTranslation } from 'react-i18next';
import FontIcon from '../../components/FontIcon/FontIcon';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ExpertDetailLoader } from '../../components/ContentLoader';

const SOCIAL_MEDIA_ICON_MAPPER = {
    instagram: faInstagram,
    facebook: faFacebookF,
    twitter: faTwitter,
    linkedin: faLinkedinIn
};

function ExpertDetails(props) {
    const { t } = useTranslation(['common']);
    // alert(props.IS_RTL);
    const CategorySettings = {
        dots: false,
        infinite: false,
        speed: 500,
        swipeToSlide: true,
        variableWidth: true,
    };
    const slug = props.match.params.slug;
    const [expert, setExpert] = useState(null);
    const [expertSocialMediaItems, setExpertSocialMediaItems] = useState([]);
    const [hasSocialMediaItems, setHasSocialMediaItems] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getExpertProfile(slug).then((result) => {
            const expertItem = result.data.data;
            const socialMediaItems = expertItem?.social_media || {};
            for (let socialMediaItem in socialMediaItems) {
                if (socialMediaItems[socialMediaItem]) {
                    setHasSocialMediaItems(true);
                    break;
                }
            }
            setLoading(false);
            setExpert(expertItem);
            setExpertSocialMediaItems(socialMediaItems);
        });
    }, []);
    if (null === expert) {
        return(
            <ExpertDetailLoader/>
        );
    }
    if (true === loading) {
        return(
            <ExpertDetailLoader/>
        );
    }
    // document.title = "Expert " + expert.name?.[props?.APP_LOCALE]
    return (
        <div className="expert-detail-page">
            {/* <div className="region-block-bar">
                <FontIcon icon={faBan}/>
                This expert is not available in your country.
            </div> */}
            <DetailContainer
                left={
                    <>
                        <div className="img-expert-container">
                            <div className="dark-overlay">
                                <h4 className="expert-name">{expert.name?.[props?.APP_LOCALE]}</h4>
                                {/* <span className="expert-category">{expert.category_formatted?.[props?.APP_LOCALE]}</span> */}
                            </div>
                            <img src={expert.profile_picture.base_url+expert.profile_picture.files['300X300']} alt={''}/>
                        </div>
                        {expert.languages.length > 0 &&
                            <div className="language-tag-container">
                                {expert.languages.map((item, index)=> (
                                    <LanguageTag key={index.toString()} name={item?.[props?.APP_LOCALE]}/>
                                ))}
                            </div>
                        }
                        <div className="dashboard-container">
                            {Object.keys(expert.stats).map((key, index) => 
                                <div key={index.toString()} className="dashboard-item">
                                    <div className="dash-number">{expert.stats[key]}</div>
                                    <div className="dash-category">{t('common:'+key)}</div>
                                </div>
                            )}
                        </div>
                        {true === hasSocialMediaItems &&
                            <>
                                <h4 style={{color: props.THEME === 'light' ? '#000' : '#fff'}}>{t('common:social')}</h4>
                                <div className="social-media-container">
                                    {Object.keys(expertSocialMediaItems).map((key, i) => {
                                        if (key === null) {
                                            return;
                                        }
                                        const expertSocialMediaItem = expertSocialMediaItems?.[key] ? expertSocialMediaItems[key].trim() : '';
                                        if (typeof expertSocialMediaItem !== 'string') {
                                            return;
                                        }
                                        if (expertSocialMediaItem.length === 0) {
                                            return;
                                        }
                                        let url;
                                        if ('linkedin' === key) {
                                            url = 'https://www.linkedin.com/company/'+expertSocialMediaItem;
                                        } else {
                                            url = 'https://www.'+key+'.com/'+expertSocialMediaItem;
                                        }
                                        return(
                                            <div key={i.toString()} className="social-media" onClick={() => window.open(url, "_blank")}>
                                                <FontIcon icon={SOCIAL_MEDIA_ICON_MAPPER[key]} />
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        }
                        {/* <div className="social-container">
                            <h5>{t('common:social')}</h5>
                            <div className="social-icon-container">
                                <img className="social-icon" src={ICON_LINKEDIN_DETAILS.default}/>
                                <img className="social-icon" src={ICON_YOUTUBE_DETAILS.default}/>
                                <img className="social-icon" src={ICON_INSTAGRAM_DETAILS.default}/>
                                <img className="social-icon" src={ICON_FACEBOOK_DETAILS.default}/>
                                <SocialMedia/>
                            </div>
                        </div> */}
                    </>
                }
                right={
                    <div style={{maxWidth: '100%'}}>
                        <h2 style={{marginTop: '0px'}} className="content-header">{t('common:about')} {expert?.name?.[props?.APP_LOCALE]}</h2>
                        <div>
                            <p className="expert-desc">
                                {expert.about?.[props?.APP_LOCALE]}
                            </p>
                        </div>
                        <div style={{marginBottom: '30px'}}>
                            <h2 className="content-header">{t('common:skills')}</h2>
                            <div style={{marginLeft: "-25px", marginRight: "-25px"}}>
                                <Slider {...CategorySettings} arrows={false} className="category-slider">
                                    {expert.categories.map((item, index)=> (
                                        <CategoryCard key={index.toString()} image={item.icon.thumb} title={item.name?.[props?.APP_LOCALE]}/>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                        <h2 style={{marginBottom: '10px'}} className="content-header">{t('common:sessions')}</h2>
                        <Row className="session-card-row">
                            {expert.sessions.map((item, index)=> (
                                <Col key={index.toString()} as={Link} lg={6} to={'/session/'+item.slug} className="session-card-wrapper">
                                    <SessionCard image={item.cover_picture.base_url+item.cover_picture.files['700X400']} title={item?.title?.[props?.APP_LOCALE]}/>
                                </Col>
                            ))}
                        </Row>
                    </div> 
                }
                {...props}
            />
        </div>
    )
}

export const DetailContainer = (props) => {
    const WIDTH = window.innerWidth;
    const { t } = useTranslation(['common']);
    return(
        <div className="detail-page">
            <Container fluid className="detail-bg">
                <Container style={{position: 'relative'}}>
                    <Link to={'/'}>
                        <button type="button" className="grey-btn">
                            {/* <img className="chevron-left" src={ICON_CHEVRON_LEFT.default}/> */}
                            {/* <FontIcon icon={props.IS_RTL === true ? faChevronRight : faChevronLeft} size={'sm'} color={'#fff'}/> */}
                            <FontIcon icon={props.IS_RTL ? faChevronRight : faChevronLeft} size={'sm'}/>
                            <span className="btn-text">{t('common:back')}</span>
                        </button>
                    </Link>
                </Container>
            </Container>
            <Container className="detail-container">
                <Row>
                    <Col lg={3} className="left-col-container">
                        <div style={{position: WIDTH > 575.98 && 'sticky', top: WIDTH > 575.98 && '100px'}}>
                            {props.left}
                        </div>
                    </Col>
                    <Col lg={9} className="right-col-container">
                        <div className="content-col">
                            {props.right}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        IS_RTL: state.app.is_rtl,
        APP_LOCALE: state.app.app_locale,
        THEME: state.app.theme
    }
}

export default connect(mapStateToProps, null)(ExpertDetails);
