import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { theme } from './theme';
// Redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore from './redux/store/configureStore';
// CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.scss';
// Pages
import Home from './pages/Home/Home';
import SearchMain from './pages/Search/SearchMain';
import SearchExpert from './pages/Search/SearchExpert';
import SearchSession from './pages/Search/SearchSession';
import SearchCategory from './pages/Search/SearchCategory';
import SearchCategoryDetail from './pages/Search/SearchCategoryDetail';
import Contact from './pages/contact/Contact';
import ForExperts from './pages/ForExperts/ForExperts';
import ExpertDetails from './pages/expert-details/ExpertDetails';
import Session from './pages/Session/Session';
import Faq from './pages/Faq/Faq';
import Articles from './pages/Articles/Articles';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import Terms from './pages/Terms/Terms';
import ArticleDetials from './pages/article-details/ArticleDetials';
import RootComponent from './components/RootComponent';
import Page404 from './pages/Page404/Page404';
import DownloadPage from './pages/DownloadPage/DownloadPage';

const { store, persistor } = configureStore();


function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ThemeProvider theme={theme}>
                    <Router>
                        <RootComponent>
                            <Switch>
                                <Route exact path="/" component={Home} />
                                <Route path="/category/:slug" component={SearchCategoryDetail} />
                                <Route path="/search/categories" component={SearchCategory} />
                                <Route path="/search/sessions" component={SearchSession} />
                                <Route path="/search/experts" component={SearchExpert} />
                                <Route path="/search" component={SearchMain} />
                                <Route path="/contact" component={Contact} />
                                <Route path="/for-experts" component={ForExperts} />
                                <Route path="/session/:slug" component={Session} />
                                <Route path="/expert/:slug" component={ExpertDetails} />
                                <Route path="/faq" component={Faq} />
                                <Route path="/privacy-policy" component={PrivacyPolicy} />
                                <Route path="/terms-and-conditions" component={Terms} />
                                <Route path="/blog/:slug" component={ArticleDetials} />
                                <Route path="/blog" component={Articles} />
                                <Route path="/download" component={DownloadPage} />
                                <Route component={Page404} />
                            </Switch>
                        </RootComponent>
                    </Router>
                </ThemeProvider>
            </PersistGate>
        </Provider>
    );
}

export default App;
