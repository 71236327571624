import './footer.scss';
import './FooterResponsive.scss';
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { IMG_FOOTER_LOGO, IMG_FOOTER_LIGHT_LOGO } from '../../assets/images/Index';
import { THEME_COLORS } from '../../utils/Constant';
import { SocialMedia } from '../../pages/Session/Session';
import FontIcon from '../FontIcon/FontIcon';
import Btn from '../Btn/Btn';
import WaveContainer from '../wave/WaveContainer';

export const DownloadPlatform = (props) => {
    const { t } = useTranslation(['common']);
    return(
        <div className="platform-download-container">
            {DOWNLOAD_PLATFORM.map((item, index)=> {
                return(
                    <a key={item.name} target="_blank" rel="noreferrer" href={item.link} className="download-platform">
                        <div className="platform-icon">
                            <FontIcon icon={item.icon}/>
                        </div>
                        <div className="download-arrow">
                            <FontIcon icon={faArrowRight}/>
                        </div>
                    </a>
                )
            })}
            {props.contact &&<Link to={'/contact'} className="contact-action">
                <div className="platform-icon">
                    {t('common:contact_us')}
                </div>
                <div className="download-arrow">
                    <FontIcon icon={props.IS_RTL ? null : faArrowRight}/>
                </div>
            </Link>}
        </div>
    )
}

function Footer(props) {
    const { t } = useTranslation(['common']);
    return (
        <>
            <Container fluid className="footer-bg" id="footer-container">
                <WaveContainer 
                    fill={THEME_COLORS?.[props.THEME].wave.FOOTER}
                    paused={false}
                />
                <Container>
                    <div className="logo-row">
                        <div className="logo-container">
                            <img alt={'WALSOS'} className="footer-logo" src={IMG_FOOTER_LOGO.default}/>
                            <img alt={'WALSOS'} className="footer-logo-light" src={IMG_FOOTER_LIGHT_LOGO.default}/>
                        </div>
                        <SocialMedia onClick={item => window.open(item.url, "_blank")} />
                    </div>
                    <Row>
                        <Col md={3} xs={12} className="footer-block-container">
                            <div>
                                <h4>{t('common:footer.legal')}</h4>
                                <Link to="/terms-and-conditions"><h4>{t('common:footer.terms_and_conditions')}</h4></Link>
                                <Link to="/privacy-policy"><h4>{t('common:footer.privacy_policy')}</h4></Link>
                            </div>
                        </Col>
                        <Col md={3} xs={12} className="footer-block-container">
                            <div>
                                <h4>{t('common:support')}</h4>
                                <Link to="/faq"><h4>{t('common:faqs')}</h4></Link>
                                <Link to="/contact"><h4>{t('common:contact_us')}</h4></Link>
                            </div>
                        </Col>
                        <Col md={3} xs={12} className="footer-block-container">
                            <div>
                                <h4>{t('common:about')}</h4>
                                {/* <Link to="/"><h4>{t('common:about_us')}</h4></Link> */}
                                <Link to="/blog"><h4>{t('common:main_navigation.blog')}</h4></Link>
                            </div>
                        </Col>
                        <Col md={3} xs={12} className="footer-block-container">
                        <div>
                            <h4>{t('common:join')}</h4>
                            <Btn 
                                // href="#footer-container"  
                                // onClick={didPressExpert} 
                                to={'/for-experts'}
                                textstyle={{paddingLeft: '20px', paddingRight: '20px',}} 
                                title={t('common:footer.are_you_an_expert')}
                            />
                        </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}

const DOWNLOAD_PLATFORM = [
    {
        name: 'Play Store',
        icon: faGooglePlay,
        link: 'https://play.google.com/store/apps/details?id=com.walsos&hl=en_IN&gl=US'
    },
    {
        name: 'App Sotre',
        icon: faApple,
        link: 'https://apps.apple.com/us/app/walsos-%D9%88%D8%A7%D9%84%D8%B3%D9%88%D8%B3/id1447090365'
    },
]

const mapStateToProps = state => {
    return {
        APP_LOCALE: state.app.app_locale,
        IS_RTL: state.app.is_rtl,
        THEME: state.app.theme
    }
}

export default connect(mapStateToProps, null)(Footer);