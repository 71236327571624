import '../../App.scss';
import './home.scss';
import './HomeResponsive.scss';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Slider from "react-slick";
import Banner from '../../components/Banner/Banner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { IMG_VIDEO_VOICE_CHAT, IMG_CONSULT_EXPERT, IMG_LOCALIZED, IMG_LOCALIZED_LIGHT, IMG_ALL_PLATFORMS, IMG_ALL_PLATFORMS_LIGHT, IMG_IN_PERSON, IMG_SECURE_2, IMG_SECURE_2_LIGHT } from '../../assets/images/Index'
import { Link, useHistory } from 'react-router-dom';
import ExpertCard from '../../components/ExpertCard/ExpertCard';
import CategoryCard from '../../components/CategoryCard/CategoryCard';
import SessionCard from '../../components/SessionCard/SessionCard';
import ArticleCard from '../../components/ArticleCard/ArticleCard';
import VideoCard from '../../components/VideoCard/VideoCard';
import WaveContainer from '../../components/wave/WaveContainer';
import { THEME_COLORS } from '../../utils/Constant';
import DialogContent from '@material-ui/core/DialogContent';
import EventEmitter from './../../service/EventEmitter';
import Dialog from '@material-ui/core/Dialog';
import { setTheme } from '../../redux/actions/app';
import {
    HomeBannerLoader,
    FeaturedExpertLoader,
    CategoryFilterLoader,
    FeaturedSessionLoader,
    FeaturedArticleLoader,
    VideoChannelFilterLoader,
    FeaturedVideoLoader,
    TestimonialLoader
} from '../../components/ContentLoader';
import {
    getFeaturedBanners,
    getFeaturedExperts,
    getCategoryList,
    getSessionList,
    getArticles,
    getTestimonials,
    getVideos
} from '../../service/Api/ApiRequest';
import { DownloadPlatform } from '../../components/Footer/Footer';

const _event = EventEmitter.getInstance();

function Home(props) {
    const { t } = useTranslation(['common', 'home']);
    const history = useHistory();
    const bannerSliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        swipeToSlide: true,
        variableWidth: true,
        mobileFirst: true,
        autoplay: false,
        rtl: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    swipe: props.IS_RTL ? false : true,
                }
            }
        ]
    };
    const onBannerClick = (item) => {
        if ('url' === item?.link?.type) {
            window.open(item?.link?.value, "_blank");
            return;
        }
        if ('expert' === item?.link?.type) {
            history.push("/expert/"+item?.link?.slug);
            return;
        }
        if ('session' === item?.link?.type) {
            history.push("/session/"+item?.link?.slug);
            return;
        }
        if ('category' === item?.link?.type) {
            history.push("/category/"+item?.link?.slug);
            return;
        }
    }
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const _getFeaturedBanners = (countryCode) => {
        getFeaturedBanners('?region='+countryCode).then((result) => {
            setItems(result.data.data);
            setLoading(false);
        });
    }
    useEffect(() => {
        // document.title = "WALSOS - Live Video Session With Experts"
        _getFeaturedBanners(props.DEVICE_COUNTRY_CODE);
        _event.on('app.region_change', (params) => {
            setLoading(true);
            _getFeaturedBanners(params?.data?.country_code);
        });
    }, []);
    return (
        <div className="home-page">
            <Container fluid className="home-container">
                <Container fluid className="slider-container">
                    <Row className="slider-content-container">
                        <Col lg={4} className="slider-info-container">
                            <h2>
                                {t('home:banner_section_title')}
                            </h2>
                            <p>
                                {t('home:banner_section_info')}
                            </p>
                            {/* <Btn title={t('common:download')} target="_blank" href="https://walsos.com/en/download-app"/> */}
                            <DownloadPlatform/>
                        </Col>
                        <Col lg={8} className="slides-container">
                            <div className="arrows-bottom">
                                {false === loading ?
                                    <Slider {...bannerSliderSettings} style={{height: '100%'}}>
                                        {items.map((item)=> (
                                            <div key={item.id.toString()} className="slides-wrapper">
                                                <Banner
                                                    title={item?.title?.[props.APP_LOCALE]}
                                                    info={item?.info?.[props.APP_LOCALE]}
                                                    image={item?.cover_picture.base_url+item?.cover_picture?.files?.['700X400']}
                                                    onClick={() => onBannerClick(item)}
                                                />
                                            </div>
                                        ))}
                                    </Slider>
                                :
                                    <HomeBannerLoader/>
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <FeaturesSection {...props} cols={FEATURES} backgroundColor={THEME_COLORS?.[props.THEME].color.GOLD}
                wave={
                    <WaveContainer 
                        fill={THEME_COLORS?.[props.THEME].wave.GOLD}
                        paused={false}
                    />
                }
            />
            <ExpertSection {...props}/>
            <SessionSection {...props}/>
            <FeaturesSection {...props} features2 cols={FEATURES_2} backgroundColor={THEME_COLORS?.[props.THEME].color.PINK}
                wave={
                    <WaveContainer 
                        fill={THEME_COLORS?.[props.THEME].wave.PINK}
                        paused={false}
                    />
                }
            />
            <ArticlesSection {...props}/>
            <AboutWalsosSection {...props}/>
            <VideoSection {...props}/>
            <TestimonialSection {...props}/>
        </div>
    );
}
const FeaturesSection = (params={}) => {
    const { t } = useTranslation(['common', 'home']);
    const featuresSettings = {
        mobileFirst:true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        swipeToSlide: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        appendDots: dots => (
            <div>
                <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
        ), customPaging: i => (
            <div className="custom-dots"></div>
        ),
        responsive: [
            {
              breakpoint: 575.98,
              settings: {
                slidesToShow: 1,
              }
            }
        ],
    };
    const cols = params.cols ? params.cols : [];
    return(
        <Container fluid className="features-section features-one" style={{backgroundColor: params.backgroundColor}}>
            {params.wave}
            <Container>
                <div style={{marginLeft: "-12px", marginRight: "-12px"}}>
                    <div className={`features-arrow ${params.features2 ? "features-pink-arrow" : ""}`}>
                        <Slider {...featuresSettings}>
                            {cols.map((col, index)=> (
                                <div key={index.toString()} className="features-container">
                                    <img alt={t(col.title)} src={col.icon[params?.THEME]}/>
                                    <h5>{t(col.title)}</h5>
                                    <p>{t(col.description)}</p>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </Container>
        </Container>
    )
}
const ExpertSection = (props) => {
    const { t } = useTranslation(['common', 'home']);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const _getFeaturedExperts = (countryCode) => {
        getFeaturedExperts('?info=true&region='+countryCode).then((result) => {
            setItems(result.data.data);
            setLoading(false);
        });
    }
    useEffect(() => {
        _getFeaturedExperts(props?.DEVICE_COUNTRY_CODE);
        _event.on('app.region_change', (params) => {
            setLoading(true);
            _getFeaturedExperts(params?.data?.country_code);
        });
    }, []);
    const expertSettings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        variableWidth: true,
        swipeToSlide: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };
    return(
        <Wrapper className="experts-section arrows-top">
            <WaveContainer 
                fill={THEME_COLORS?.[props.THEME].wave.BACKGROUND}
                paused={false}
            />
            <Container>
                <h2>{t('home:banner_section_title')}</h2>
                <p>{t('home:banner_section_info')}</p>
            </Container>
            <Container>
                <Row>
                    <Col lg={12}>
                        <div style={{marginLeft: "-12px", marginRight: "-12px"}}>
                            {false === loading ?
                                <Slider {...expertSettings}>
                                    {items.map((item, index) => (
                                        <div key={index.toString()} className="expert-card-parent">
                                            <ExpertCard slug={item.slug} image={item.profile_picture.base_url+item.profile_picture.files['300X300']} name={item?.name?.[props.APP_LOCALE]} expertise={item?.categories?.[props?.APP_LOCALE]}/>
                                        </div>
                                    ))}
                                </Slider>
                            :
                                <FeaturedExpertLoader/>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </Wrapper>
    )
}
const SessionSection = (props) => {
    const { t } = useTranslation(['common', 'home']);
    const sessionSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        swipeToSlide: true,
        rows: 2,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 575.98,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    swipe: props.IS_RTL ? false : true,
                }
            }
        ],
        appendDots: dots => (
            <div>
                <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
        ), customPaging: i => (
            <div className="custom-dots"></div>
        )
    };
    const CategorySettings = {
        dots: false,
        infinite: true,
        speed: 500,
        swipeToSlide: true,
        variableWidth: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 575.98,
                settings: {
                    swipe: props.IS_RTL ? false : true,
                }
            }
        ],
    };
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [loadingCategories, setLoadingCategories] = useState(true);
    const [sessions, setSessions] = useState([]);
    const [loadingSessions, setLoadingSessions] = useState(true);
    const _getSessionListForCategory = (categoryId, countryCode, displayLoader = false) => {
        if (true === displayLoader) {
            setLoadingSessions(true);
        }
        getSessionList('?sort_by=booking_count&sort_order=desc&category='+categoryId+'&region='+countryCode).then((sessionResult) => {
            setSessions(sessionResult.data.data);
            setLoadingSessions(false);
        });
    }
    useEffect(() => {
        getCategoryList('?sort_by=booking_count&sort_order=desc&limit=-1').then((categoryResult) => {
            const categories = categoryResult.data.data;
            setSelectedCategory(categories[0]);
            setCategories(categories);
            setLoadingCategories(false);
            _getSessionListForCategory(categories[0].id, props.DEVICE_COUNTRY_CODE, false);
            _event.on('app.region_change', (params) => {
                _getSessionListForCategory(selectedCategory.id, params?.data?.country_code, true);
            });
        });
    }, []);
    const didPressCategoryItem = (item) => {
        setSelectedCategory(item);
        _getSessionListForCategory(item.id, props.DEVICE_COUNTRY_CODE, true);
    }
    return(
        <Wrapper className="session-section">
            <WaveContainer 
                fill={THEME_COLORS?.[props.THEME].wave.MAIN_BACKGROUND}
                paused={false}
            />
            <Container>
                <h3>{t('home:browse_sessions')}</h3>
                <div className="category-container">
                    {false === loadingCategories ?
                        <>
                            <Slider {...CategorySettings} className="category-slider">
                                {categories.map((item)=> (
                                    <CategoryCard
                                        key={item.id.toString()}
                                        title={item?.name?.[props.APP_LOCALE]}
                                        image={item.icon.thumb}
                                        onClick={() => didPressCategoryItem(item)}
                                    />
                                ))}
                            </Slider>
                            {/* <div className="category-prev-arrow-bg"></div>
                            <div className="category-next-arrow-bg"></div> */}
                        </>
                    :
                        <div style={{marginBottom: '80px'}}>
                            <CategoryFilterLoader/>
                        </div>
                    }
                    <div className="category-prev-arrow-bg"/>
                    <div className="category-next-arrow-bg"/>
                </div>
                <Row className="session-section-content-container">
                    <Col lg={12} style={{paddingLeft: 7, paddingRight: 7}}>
                        <div className="arrows-bottom">
                            {false === loadingSessions ?
                                <Slider {...sessionSettings} >
                                    {sessions.map((item)=> (
                                        <Col key={item.id.toString()} as={Link} to={'/session/'+item.slug} className="session-card-wrapper">
                                            <SessionCard image={item.cover_picture.base_url+item.cover_picture.files['700X400']} title={item?.title?.[props.APP_LOCALE]}/>
                                        </Col>
                                    ))}
                                </Slider>
                            :
                                <FeaturedSessionLoader/>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </Wrapper>
    )
}
const ArticlesSection = (props) => {
    const { t } = useTranslation(['common', 'home']);
    const articleSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        swipeToSlide: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 575.98,
                settings: {
                    slidesToShow: 1.1,
                    slidesToScroll: 1,
                    
                }
            }
        ],
        appendDots: dots => (
            <div>
                <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
        ), customPaging: i => (
            <div className="custom-dots"></div>
        )
    };
    const [articles, setArticles] = useState([]);
    const [loadingArticle, setLoadingArticle] = useState(true);
    useEffect(() => {
        getArticles('?summary=true').then((articleResult) => {
            setArticles(articleResult.data.data);
            setLoadingArticle(false);
        });
    }, []);
    if (false === loadingArticle && articles.length === 0) {
        return null;
    }
    return (
        <Wrapper className="article-container">
            <WaveContainer 
                fill={THEME_COLORS?.[props.THEME].wave.MAIN_BACKGROUND}
                paused={false}
            />
            <Container>
                <h3>{t('home:articles')}</h3>
                <Row>
                    <Col lg={12} className={'article-row-container'} style={{marginLeft: -15, marginRight: -15}}>
                        <div className="arrows-bottom">
                            {false === loadingArticle ?
                                <Slider {...articleSettings}>
                                    {articles.map((item, index)=> (
                                        <Col key={index.toString()} className="article-col-container">
                                            <ArticleCard
                                                link
                                                image={item.cover_picture.base_url+item.cover_picture.files['500X500']}
                                                title={item?.title?.[props.APP_LOCALE]}
                                                slug={item.slug}
                                            />
                                        </Col>
                                    ))}
                                </Slider>
                            :
                                <FeaturedArticleLoader/>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </Wrapper>
    )
}
export const AboutWalsosSection = (props) => {
    const { t } = useTranslation(['common', 'home']);
    const [open, setOpen] = useState(false);
    const { onClose, value: valueProp, ...other } = props;
    const videoCardSettings = {
        arrows: true,
        dots: true,
        infinite: true,
        // speed: 500,
        swipeToSlide: true,
        variableWidth: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 575.98,
                settings: {
                    swipe: props.IS_RTL ? false : true
                }
            }
        ],
        appendDots: dots => (
            <div>
                <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
        ), customPaging: i => (
            <div className="custom-dots"></div>
        )
    };
    const handleEntering = () => {
        
    };
    
    const handleCancel = () => {
        setOpen(false);
    };

    const didPressCountry = () => {
        setOpen(true);
    }
    return(
        <>
            <Wrapper bgcolor={props.bgcolor ? props.bgcolor : THEME_COLORS?.[props.THEME].color.BG_COLOR2} className="about-walsos-section">
                <WaveContainer 
                    fill={props.wavecolor ? props.wavecolor : THEME_COLORS?.[props.THEME].wave.BACKGROUND}
                    paused={false}
                />
                <Container className="learn-more-container">
                    <Title>{t('home:learn_more_about_walsos')}</Title>
                    <div className="arrows-bottom">
                        <Slider {...videoCardSettings}>
                            {WALSOS_VIDEOS.map((item, index)=> {
                                return(
                                    <div onClick={didPressCountry} key={index.toString()} className="video-card-wrapper">
                                        <VideoCard big image={item.image} title={item.title}/>
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                </Container>
                <Dialog
                    onBackdropClick={handleCancel}
                    fullWidth={true}
                    onEntering={handleEntering}
                    aria-labelledby="confirmation-dialog-title"
                    open={open}
                    {...other}
                    className="video-modal"
                    maxWidth="lg"
                >
                    <DialogContent dividers style={{padding: 0, border: 'none'}}>
                        <iframe title="About WALSOS" width="100%" height="100%" src="https://www.youtube.com/embed/BROBxMGWK7A?autoplay=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </DialogContent>
                </Dialog>
            </Wrapper>
        </>
    )
}

const VideoSection = (props) => {
    const videosSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2.7,
        swipeToSlide: true,
        rows: 2,
        centerMode: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 575.98,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    swipe: props.IS_RTL ? false : true,     
                }
            }
        ],
        appendDots: dots => (
            <div>
                <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
        ), customPaging: i => (
            <div className="custom-dots"></div>
        )
    };
    const handleEntering = () => {};
    const handleCancel = () => { setOpen(false); };
    const { onClose, value: valueProp, ...other } = props;
    const [open, setOpen] = useState(false);
    const [channels, setChannels] = useState([]);
    const [videos, setVideos] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState([]);
    const [loadingChannels, selectedLoadingChannels] = useState(true);
    const [loadingVideos, selectedLoadingVideos] = useState(true);
    const didSelectChannelItem = (item) => {
        selectedLoadingVideos(true);
        getVideos('?data_type=video&channel_slug='+item.slug).then((videoResult) => {
            const videos = videoResult.data.data || [];
            setVideos(videos);
            selectedLoadingVideos(false);
        });
    }
    const didSelectVideoItem = (item) => {
        setSelectedVideo(item);
        setOpen(true);
    }
    useEffect(() => {
        getVideos('?data_type=channel').then((result) => {
            const channels = result.data.data || [];
            setChannels(channels);
            selectedLoadingChannels(false);
            if (channels.length > 0) {
                const selectedChannel = channels[0];
                getVideos('?data_type=video&channel_slug='+selectedChannel.slug).then((videoResult) => {
                    const videos = videoResult.data.data || [];
                    setVideos(videos);
                    selectedLoadingVideos(false);
                });
            }
        });
    }, []);
    const { t } = useTranslation(['common']);
    return(
        <>
            <Wrapper className="videos-section">
                <WaveContainer 
                    fill={THEME_COLORS?.[props.THEME].wave.MAIN_BACKGROUND}
                    paused={false}
                />
                <Container className="videos-section-container">
                    <Title>{t('common:videos')}</Title>
                    <div className="videos-tabs-container">
                        {false === loadingChannels ?
                            <>
                                {channels.map((item)=> {
                                    return(
                                        <div key={item.id.toString()} href="#" onClick={() => didSelectChannelItem(item)} className="tab-btn">
                                            {item?.title?.[props.APP_LOCALE]}
                                        </div>
                                    )
                                })}
                            </>
                        :
                            <VideoChannelFilterLoader/>
                        }
                    </div>
                    <Row>
                        <div className="arrows-bottom" style={{width: '100%'}}>
                        {false === loadingVideos ?
                            <Slider {...videosSettings}>
                                {videos.map((item)=> (
                                    <Col key={item.id.toString()} className="video-card-wrapper" onClick={() => didSelectVideoItem(item)}>
                                        <VideoCard borderColor={'#9EC2BB'} height={'220px'} image={item.cover_picture.base_url+item.cover_picture.files['400X250']} title={item?.title?.[props.APP_LOCALE]}/>
                                    </Col>
                                ))}
                            </Slider>
                        :
                            <FeaturedVideoLoader/>
                        }
                        </div>
                    </Row>
                </Container>
                <Dialog
                    onBackdropClick={handleCancel}
                    fullWidth={true}
                    onEntering={handleEntering}
                    aria-labelledby="confirmation-dialog-title"
                    open={open}
                    className="video-modal"
                    maxWidth="lg"
                    {...other}
                >
                    <DialogContent dividers style={{padding: 0, border: 'none'}}>
                        <iframe title={selectedVideo?.title?.[props.APP_LOCALE]} width="100%" height="100%" src={'https://www.youtube.com/embed/'+selectedVideo?.youtube_video_id+'?autoplay=1'} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </DialogContent>
                </Dialog>
            </Wrapper>
        </>
    )
}
export const TestimonialSection = (props) => {
    const settings = {
        dots: false,
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        appendDots: dots => (
            <div>
                <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
        ),
        customPaging: i => (
            <div className="custom-dots"></div>
        )
    };
    const [testimonials, setTestimonials] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getTestimonials().then((testimonialResult) => {
            setTestimonials(testimonialResult.data.data);
            setLoading(false);
        });
    }, []);
    return(
        <Wrapper className="testimonial-section" style={{backgroundColor: THEME_COLORS?.[props.THEME].color.TESTIMONIAL_BG}}>
            <WaveContainer 
                fill={THEME_COLORS?.[props.THEME].wave.TESTIMONIAL_BG}
                paused={false}
            />
            <Container className="testimonial-section-container">
                <div className="testimonial-arrows-parent">
                    {false === loading ?
                        <Slider {...settings}>
                            {testimonials.map((item, index)=> {
                                return(
                                    <div key={item.id.toString()} className="testimonials-slide-container">
                                        <div className="testimonials-slide-content-container">
                                            <img alt={item?.name?.[props.APP_LOCALE]} src={item.picture.base_url+item.picture.files['150X150']}/>
                                            <p>“{item?.message?.[props.APP_LOCALE]}”</p>
                                            <h6>{item?.name?.[props.APP_LOCALE]}</h6>
                                        </div>
                                    </div>
                                )
                            })}
                        </Slider>
                    :
                        <TestimonialLoader/>
                    }
                </div>
                <div className="download-wrapper">
                    <DownloadBanner/>
                </div>
            </Container>
        </Wrapper>
    )
}
export const DownloadBanner = (props) => {
    return(
        <div className={`download-banner-container  ${props.inverse ? 'download-banner-inverse' : ''} ${props.inverse ? '' : 'btn-light-switch'}`}>
        {/* <div className="download-banner-container download-banner-inverse" style={{backgroundColor: props.inverse ? '#F4C85E' : '#000' }}> */}
            <h3 className="title-primary">
                {/* {('home:download_walsos_mobile_application_now')} */}
                <Trans i18nKey="home:download_walsos_mobile_application_now"/>
            </h3>
            <p>
                <Trans i18nKey="home:available_for_Download_on_Apple_App_Store"/>
            </p>
            {/* <Btn target="_blank" href="https://walsos.com/en/download-app" title={t('common:download')}/> */}
            <DownloadPlatform/>
        </div>
    )
}
const Wrapper = (props) => {
    const bgColor = props.bgcolor;
    return(
        <Container fluid style={{paddingTop: '0px', paddingBottom: '280px', position: 'relative', backgroundColor: bgColor}} {...props}>
            {props.children}
        </Container>
    )
}
const Title = (params={}) => {
    return(
        <h3 className="section-title" {...params}>{params.children}</h3>
    )
}


export function NextArrow(props) {
    const { onClick } = props;
    return (
        <div className="slick-arrow-next" onClick={onClick}>
            <FontAwesomeIcon className="carousal-icon" icon={faChevronRight}/>
        </div>
    );
}

export function PrevArrow(props) {
    const { onClick } = props;
    return (
        <div className="slick-arrow-prev" onClick={onClick}>
            <FontAwesomeIcon className="carousal-icon" icon={faChevronLeft}/>
        </div>
    );
}

const FEATURES = [
    {
        icon: {
            light: IMG_CONSULT_EXPERT.default,
            dark: IMG_CONSULT_EXPERT.default
        },
        title: 'home:consult_experts',
        description: 'home:we_have_doctors_lawyers_actors_beauticians_and_various_other_experts'
    },
    {
        icon: {
            light: IMG_VIDEO_VOICE_CHAT.default,
            dark: IMG_VIDEO_VOICE_CHAT.default
        },
        title: 'home:video_voice_chat',
        description: 'home:consult_with_any_expert_from_a_wide_range_of_fields'
    },
    {
        icon: {
            light: IMG_IN_PERSON.default,
            dark: IMG_IN_PERSON.default
        },
        title: 'home:in_person_consultation',
        description: 'home:booking_in_person_appointment_with_an_expert'
    }
]
const FEATURES_2 = [
    {
        icon: {
            light: IMG_SECURE_2_LIGHT.default,
            dark: IMG_SECURE_2.default
        },
        title: 'home:secure_private',
        description: 'home:your_data_privacy_and_security_matter_to_us'
    },
    {
        icon: {
            light: IMG_ALL_PLATFORMS_LIGHT.default,
            dark: IMG_ALL_PLATFORMS.default
        },
        title: 'home:available_on_all_platforms',
        description: 'home:we_are_available_on_ios_android_and_web'
    },
    {
        icon: {
            light: IMG_LOCALIZED_LIGHT.default,
            dark: IMG_LOCALIZED.default
        },
        title: 'home:localized_experience',
        description: 'home:you_can_search_and_browse_experts_from_your_country'
    }
]
const WALSOS_VIDEOS = [
    {
        image: 'https://i.ibb.co/YbLG0Ts/video-1.png',
        title: 'Best experts from around the world on Walsos!'
    },
    {
        image: 'https://i.ibb.co/dtKhHHK/video-2.png',
        title: 'Connect with Experts Anywhere, Anytime.'
    }
];

const mapStateToProps = state => {
    return {
        APP_LOCALE: state.app.app_locale,
        IS_RTL: state.app.is_rtl,
        THEME: state.app.theme,
        DEVICE_COUNTRY_CODE: state.app?.device_country?.country_code || 'kw'
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setTheme: (data) => dispatch(setTheme(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
