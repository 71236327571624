import '../../App.scss';
import './search.scss';
import React, { useState, useEffect } from 'react';
import { Container, InputGroup, FormControl, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { faChevronLeft, faChevronRight, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useHistory } from 'react-router-dom';
import EventEmitter from './../../service/EventEmitter';
import queryString from 'query-string';
import { IMG_SEARCH_BG } from '../../assets/images/Index';
import { connect } from 'react-redux';
import ExpertCard from '../../components/ExpertCard/ExpertCard';
import FontIcon from '../../components/FontIcon/FontIcon';
import { getExpertList } from '../../service/Api/ApiRequest';
import { SearchExpertLoader } from '../../components/ContentLoader';
import { RoundedBtn, SearchNotFound } from '../../utils/UIComponent/UIComponent';

const _event = EventEmitter.getInstance();

function SearchExpert(props) {
    const { t } = useTranslation(['search']);
    const history = useHistory();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const queryParamsPage = parseInt(queryParams?.page) || 1;
    const queryParamsKeyword = queryParams?.q || '';
    const [searchText, setSearchText] = useState(queryParamsKeyword);
    const [experts, setExperts] = useState([]);
    const [loadingExperts, setLoadingExperts] = useState(true);
    const [hasPrevPage, setHasPrevPage] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(false);
    const _getExpertList = (page, keyword, countryCode) => {
        getExpertList('?limit=60&page='+page+'&keyword='+keyword+'&region='+countryCode).then((result) => {
            const resultParams = result?.data?.params || {};
            setExperts(result.data.data);
            setLoadingExperts(false);
            setHasPrevPage(resultParams?.has_prev_page);
            setHasNextPage(resultParams?.has_next_page);
        });
    }
    useEffect(() => {
        _getExpertList(queryParamsPage, searchText, props.DEVICE_COUNTRY_CODE);
        _event.on('app.region_change', (params) => {
            setLoadingExperts(true);
            _getExpertList(queryParamsPage, searchText, params?.data?.country_code);
        });
    }, [location]);
    const onUserSearch = (event) => {
        const text = event.currentTarget.value;
        setLoadingExperts(true);
        setSearchText(text);
        _getExpertList(queryParamsPage, text, props.DEVICE_COUNTRY_CODE);
    }
    const didPressPaginationNextBtn = () => {
        setLoadingExperts(true);
        const nextPage = queryParamsPage + 1;
        const queryParamsKeyword = queryParams?.q || '';
        history.push("/search/experts?page="+nextPage+'&q='+queryParamsKeyword);
    }
    const didPressPaginationPrevBtn = () => {
        setLoadingExperts(true);
        const prevPage = queryParamsPage - 1;
        const queryParamsKeyword = queryParams?.q || '';
        history.push("/search/experts?page="+prevPage+'&q='+queryParamsKeyword);
    }
    let paginationBtnAlignmentClass = 'pagination-btn-container';
    if (true === hasPrevPage && false === hasNextPage) {
        paginationBtnAlignmentClass += ' pagination-flex-start';
    }
    if (false === hasPrevPage && true === hasNextPage) {
        paginationBtnAlignmentClass += ' pagination-flex-end';
    }
    return (
        <div className="searchpage-container search-expert-container">
            <Container fluid className="search-bg">
                <div className="search-img-absolute-container">
                    <div style={{ height: '100%', width: '100%',position: 'relative'}}>
                    <img className="absolute-searcg-bg" src={IMG_SEARCH_BG.default} alt={''}/>
                    </div>
                </div>
                <Container className="search-container">
                    <div className="search-content-container">
                        <h2>{t('search:search_experts')}</h2>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                    <FontIcon className="search-icon" icon={faSearch}/>
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder={t('search:search_experts')}
                                aria-label="Search experts"
                                aria-describedby="basic-addon1"
                                value={searchText}
                                onChange={onUserSearch}
                            />
                        </InputGroup>
                    </div>
                </Container>
            </Container>
            <Container fluid className="category-section">
                {/* <WaveContainer 
                    fill={THEME_COLORS?.[props.THEME].wave.BACKGROUND}
                    paused={false}
                /> */}
                <Container className="category-container">
                    {experts.length > 0 && 
                        <div className="category-header-row" style={{marginTop: '20px'}}>
                            <div>
                                <h3>{t('search:all_experts')}</h3>
                            </div>
                        </div>
                    }
                    {false === loadingExperts ?
                        <div className="arrows-bottom">
                            <Row>
                                {experts.map((item, index) => (
                                    <Col md={3} lg={3} xl={2} xs={6} key={index.toString()} className="expert-card-parent">
                                        <ExpertCard slug={item.slug} image={item.profile_picture.base_url+item.profile_picture.files['300X300']} name={item?.name?.[props.APP_LOCALE]} expertise={item?.categories?.[props?.APP_LOCALE]}/>
                                    </Col>
                                ))}
                                {experts.length <= 0 &&
                                    <SearchNotFound/>
                                }
                            </Row>
                        </div>
                    :
                        <div style={{marginBottom: '80px'}}>
                            <SearchExpertLoader/>
                        </div>
                    }
                    <div className={paginationBtnAlignmentClass}>
                        {true === hasPrevPage &&
                            <RoundedBtn icon={props.IS_RTL ? faChevronRight : faChevronLeft} prev onClick={didPressPaginationPrevBtn}/>
                        }
                        {true === hasNextPage &&
                            <RoundedBtn icon={props.IS_RTL ? faChevronLeft : faChevronRight} onClick={didPressPaginationNextBtn}/>
                        }
                    </div>
                </Container>
            </Container>
        </div>
    );
}


const mapStateToProps = state => {
    return {
        APP_LOCALE: state.app.app_locale,
        IS_RTL: state.app.is_rtl,
        THEME: state.app.theme,
        DEVICE_COUNTRY_CODE: state.app?.device_country?.country_code || 'kw'
    }
}

export default connect(mapStateToProps, null)(SearchExpert);