import React from 'react'
import './DownloadPage.scss';
import './DownloadPageResponsive.scss';
import { useTranslation } from 'react-i18next';
import { AssistiveBanner, Header } from '../Faq/Faq'
import { IMG_DOWNLOAD, IMG_APP_STORE_MOBILE, IMG_PLAY_STORE_MOBILE, IMG_APPSTORE, IMG_PLAYSTORE, IMG_FAQ_LIGHT, IMG_FAQ, ICON_GOLDEN_RIGHT_ARROW } from './../../assets/images/Index';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { THEME_COLORS } from '../../utils/Constant';
import WaveContainer from '../../components/wave/WaveContainer';

function DownloadPage(props) {
    const { t } = useTranslation(['common']);
    const PLATFORM = [
        {
            name: 'iOS',
            image: IMG_APP_STORE_MOBILE.default,
            storeIcon: IMG_APPSTORE.default,
            hasAPK: false,
            link: 'https://apps.apple.com/us/app/walsos-%D9%88%D8%A7%D9%84%D8%B3%D9%88%D8%B3/id1447090365'
        },
        {
            name: 'Android',
            image: IMG_PLAY_STORE_MOBILE.default,
            storeIcon: IMG_PLAYSTORE.default,
            hasAPK: true,
            link: 'https://play.google.com/store/apps/details?id=com.walsos&hl=en_IN&gl=US'
        },
    ]
    return (
        <div className="download-page-container">
            <Header background={null} title={t('common:download')}/>
            <Container className="download-platform-container">
                <Row className="download-platform-row">
                    {PLATFORM.map((item,index)=> {
                        return(
                            <Col key={index.toString()} xs={12} md={6} className="download-platform-col">
                                <a href={item.link} target="_blank" rel="noreferrer">
                                    <div className="download-platform-content-container">
                                        <div className="download-platform-image-container">
                                            <img className="platform-hardware" src={item.image} alt={''}/>
                                            <div className={'download-platform-overlay'}>
                                                <img src={item.storeIcon} alt={''}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="platform-info-container">
                                        <h4>{item.name}</h4>
                                        {item.hasAPK &&
                                            <div className="direct-download-container">
                                                <a href="#">{t('common:or_click_here_for_direct_link')}</a>
                                                <span>
                                                    {/* <FontIcon icon={faFileDownload}/> */}
                                                    {/* <img src={IMG_DOWNLOAD.default} alt=""/> */}
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35"><g id="Layer_2" data-name="Layer 2"><g id="logo-test"><path id="download-icon" d="M1,17.5A16.5,16.5,0,1,1,17.5,34,16.5,16.5,0,0,1,1,17.5Zm13.414-.413,3.146,3.146,3.14605-3.146L17.56,20.233V10.8321M10.85789,24.32983H24.32661" fill="none" stroke="#f4725c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></g></g></svg>
                                                </span>
                                            </div>
                                        }
                                    </div>
                                </a>
                            </Col>
                        )
                    })}
                </Row>
            </Container>
            <AssistiveBanner
                image={props.THEME === 'light' ? IMG_FAQ_LIGHT.default : IMG_FAQ.default}
                title={t('contact:need_quick_assistance') + '\n' + t('contact:check_frequently_asked_questions')}
                reverse={false}
                btnTitle={<img alt="FAQ" className="golden-right-arrow" src={ICON_GOLDEN_RIGHT_ARROW.default}/>}
                {...props}
                to={'/faq'}
                wave={
                    <WaveContainer
                        fill={THEME_COLORS?.[props.THEME].wave.MAIN_BACKGROUND}
                        paused={false}
                    />
                }
            />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        APP_LOCALE: state.app.app_locale,
        IS_RTL: state.app.is_rtl,
        THEME: state.app.theme
    }
}

export default connect(mapStateToProps, null)(DownloadPage);
