import React from 'react'
import { Link } from 'react-router-dom'
import './btn.scss';

export default function Btn(props) {
    return (
        <>
        {props.href
        ?
        <a className="btn-container" style={props.style} href={props.href} {...props}>
            <div style={props.textstyle}>
                {props.title}
            </div>
        </a>
        :
        <Link className="btn-container" style={props.style} to={props.to} {...props}>
            <div style={props.textstyle}>
                {props.title}
            </div>
        </Link>
        }
        </>
    )
}
