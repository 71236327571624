import './PrivacyPolicy.scss';
import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { privacyPolicy } from './../../service/Api/ApiRequest';
import { PrivacyLoader } from '../../components/ContentLoader';
import { useTranslation } from 'react-i18next';

function PrivacyPolicy(props) {
    const { t } = useTranslation(['common']);
    const [data, setData] = useState('');
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        privacyPolicy().then((result) => {
            setLoading(false);
            setData(result.data.data);
        });
    }, []);
    return (
        <>
            <TitleHighlited title={t('common:privacy_data')} highlited={t('common:policy')}/>
            {   loading === false ?
                <Container style={{paddingBottom: '200px', paddingTop: '60px'}}>
                    <div className="para-black-container" dangerouslySetInnerHTML={{__html: data?.[props.APP_LOCALE]}}></div>
                </Container>
                :
                <PrivacyLoader/>
            }
        </>
    )
}

export const TitleHighlited = (props) => (
    <Container fluid className="header-bg-container">
        <h1 className="highlited-title">{props.title} <span>{props.highlited}</span></h1>
    </Container>
)

const mapStateToProps = state => {
    return {
        APP_LOCALE: state.app.app_locale,
        IS_RTL: state.app.is_rtl,
        THEME: state.app.theme
    }
}

export default connect(mapStateToProps, null)(PrivacyPolicy);