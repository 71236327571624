import FingerprintJS from '@fingerprintjs/fingerprintjs';
import * as Device from 'react-device-detect';

export const deviceInfo = (callback) => {
    FingerprintJS.load().then(fp => {
        fp.get().then(result => {
            const visitorId = result.visitorId;
            const model = Device.osName+' ('+Device.browserName+' '+Device.browserVersion+')';
            const DEVICE_INFO = {};
            DEVICE_INFO.device_identifier = visitorId;
            DEVICE_INFO.device_option = {
                model: model,
                is_mobile: true === Device.isMobile ? 'true' : 'false',
                is_tablet: true === Device.isTablet ? 'true' : 'false',
                is_desktop: true === Device.isDesktop ? 'true' : 'false',
                is_android: true === Device.isAndroid ? 'true' : 'false',
                is_ios: true === Device.isIOS ? 'true' : 'false',
                is_chrome: true === Device.isChrome ? 'true' : 'false',
                is_firefox: true === Device.isFirefox ? 'true' : 'false',
                is_safari: true === Device.isSafari ? 'true' : 'false',
                is_opera: true === Device.isOpera ? 'true' : 'false',
                is_ie: true === Device.isIE ? 'true' : 'false',
                is_edge: true === Device.isEdge ? 'true' : 'false',
                is_chromium: true === Device.isChromium ? 'true' : 'false',
                os_version: Device.osVersion,
                os_name: Device.osName,
                full_browser_version: Device.fullBrowserVersion,
                browser_version: Device.browserVersion,
                browser_name: Device.browserName,
                mobile_vendor: Device.mobileVendor,
                mobile_model: Device.mobileModel,
                engine_name: Device.engineName,
                engine_version: Device.engineVersion,
                user_agent: Device.getUA,
                device_type: Device.deviceType
            };
            return callback(DEVICE_INFO);
        });
    });
}