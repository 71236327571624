export const LOGO = require('./logo.png');
export const LOGO_LIGHT = require('./logo-light.png');
export const KUWAIT_FLAG = require('./kuwait-flag.png');
export const ICON_SUN = require('./sun.png');
export const IMG_GOLDEN_ARROW = require('./search-arrow.png');
export const ICON_SEARCH = require('./search-icon.png');
export const ICON_CHEVRON_BOTTOM = require('./chevron-bottom.png');
export const ICON_CHEVRON_LEFT = require('./chevron-left.png');
export const IMG_VIDEO_VOICE_CHAT = require('./video-voice-chat.svg');
export const IMG_VIDEO_VOICE_CHAT_2 = require('./video-voice-chat-2.svg');
export const IMG_IN_PERSON = require('./in-person.svg');
export const IMG_IN_PERSON_2 = require('./in-person-2.svg');
export const IMG_SECURE = require('./secure-private.svg');
export const IMG_SECURE_2 = require('./secure-private-2.svg');
export const IMG_SECURE_2_LIGHT = require('./secure-private-2-light.svg');
export const IMG_FOOTER_LOGO = require('./footer-logo.png');
export const IMG_FOOTER_LIGHT_LOGO = require('./footer-logo-light.png');
export const ICON_LINKEDIN = require('./icon-linkedin.png');
export const ICON_YOUTUBE = require('./icon-youtube.png');
export const ICON_INSTAGRAM = require('./icon-instagram.png');
export const ICON_FACEBOOK = require('./icon-facebook.png');
export const IMG_BG_SHAPE_RED = require('./bg-shape-2.svg');
export const IMG_BG_SHAPE_YELLOW = require('./bg-shape-1.svg');
export const ICON_CHEVRON_RIGHT = require('./chevron-right.png');
export const ICON_PLAY_VIDE = require('./play-video.png');
export const IMG_CONFETTI = require('./confetti.svg');
export const IMG_FEATURES_MOBILE = require('./features-screens.png');
export const ICON_LIST_BOARD = require('./list-board.svg');
export const ICON_LIST_BOARD_2 = require('./list-board-2.svg');
export const ICON_LIST_BOARD_2_LIGHT = require('./list-board-2-light.svg');
export const ICON_VIDEO_CAM = require('./video-cam.svg');
export const ICON_VIDEO_CAM_LIGHT = require('./video-cam-light.svg');
export const ICON_PRIVATE_SHIELD = require('./private-shield.svg');
export const ICON_PRIVATE_SHIELD_2 = require('./secure-private-2.svg');
export const ICON_PLAY_VIDEO = require('./play-and-video.svg');
export const ICON_INPERSON = require('./in-person-icon.svg');
export const ICON_TIMER_CLOCK = require('./timer-clock.svg');
export const ICON_TIMER_CLOCK_LIGHT = require('./timer-clock-light.svg');
export const ICON_CHECK = require('./check-mark.svg');
export const ICON_LINKEDIN_DETAILS = require('./details-linkedin-icon.png');
export const ICON_YOUTUBE_DETAILS = require('./details-youtube-icon.png');
export const ICON_INSTAGRAM_DETAILS = require('./details-instagram-icon.png');
export const ICON_FACEBOOK_DETAILS = require('./details-facebook-icon.png');
export const ICON_TIMER = require('./timer.svg');
export const ICON_TIMER_LIGHT = require('./timer-light.svg');
export const ICON_VIDEO = require('./video-camera.svg');
export const ICON_VIDEO_LIGHT = require('./video-camera-light.svg');
export const ICON_MAP_PIN = require('./map.svg');
export const ICON_MAP_PIN_LIGHT = require('./map-light.svg');
export const ICON_LINKEDIN_SVG = require('./linkedin.svg');
export const ICON_YOUTUBE_SVG = require('./youtube.svg');
export const ICON_INSTAGRAM_SVG = require('./instagram.svg');
export const ICON_FACEBOOK_SVG = require('./facebook.svg');
export const IMG_FAQ_BG = require('./faq-bg.svg');
export const IMG_CONTACT_BG = require('./contact-bg.svg');
export const IMG_ARTICLE_BG = require('./article-bg.png');
export const IMG_CONTACT = require('./contact-1.svg');
export const IMG_CONTACT_LIGHT = require('./contact-1-light.svg');
export const IMG_FAQ = require('./faq-1.svg');
export const IMG_FAQ_LIGHT = require('./faq-1-light.svg');
export const IMG_SEARCH_BG = require('./search-bg.png');
export const IMG_EXPERTS_COLLAGE_LEFT = require('./experts-collage-left.png');
export const IMG_EXPERTS_COLLAGE_RIGHT = require('./experts-collage-right.png');
export const ICON_GOLDEN_RIGHT_ARROW = require('./golden-right-arrow.png');
export const ICON_GOLDEN_LEFT_ARROW = require('./golden-left-arrow.png');
export const ICON_VIDEO_CALLING = require('./icon-expert-profile.png');
export const ICON_NOTIFICATION_ALERT = require('./notification-alert.png');
export const IMG_404 = require('./404.svg');
export const IMG_404_LIGHT = require('./404-light.svg');
export const IMG_404_ILLUSTRATION = require('./404-illustration.svg');
export const IMG_404_ILLUSTRATION_LIGHT = require('./404-illustration-light.svg');
export const IMG_SEARCH_NOT_FOUND = require('./search-not-found.png');
export const IMG_CONSULT_EXPERT = require('./expert-mobile.svg');
export const IMG_LOCALIZED = require('./localized.svg');
export const IMG_LOCALIZED_LIGHT = require('./localized-light.svg');
export const IMG_ALL_PLATFORMS = require('./all-platforms.svg');
export const IMG_ALL_PLATFORMS_LIGHT = require('./all-platforms-light.svg');
export const IMG_DIAGRAMS_GROUP = require('./diagram-group.png');
export const IMG_APP_STORE_MOBILE = require('./download-ios.png');
export const IMG_PLAY_STORE_MOBILE = require('./download-android.png');
export const IMG_DOWNLOAD = require('./download-icon.svg');
export const IMG_APPSTORE = require('./appstore.png');
export const IMG_PLAYSTORE = require('./playstore.png');

export const ICON_CATEGOTY_PERSONAL = require('./icon-category-personal.png');
export const ICON_CATEGOTY_HUMANDEV = require('./icon-human-dev.png');
export const ICON_CATEGOTY_EMOTIONAL = require('./icon-category-emotional.png');
export const ICON_CATEGOTY_FAMILY = require('./icon-category-family.png');
export const ICON_CATEGOTY_EDUCATIONAL = require('./icon-category-educational.png');