import './article-details.scss';
import './ArticleDetailsResponsive.scss';
import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom'; 
import { connect } from 'react-redux';
import moment from 'moment';
import { SocialMedia } from '../Session/Session';
import ArticleCard from '../../components/ArticleCard/ArticleCard';
import WaveContainer from '../../components/wave/WaveContainer';
import { getArticles } from './../../service/Api/ApiRequest';
import { useTranslation } from 'react-i18next';
import { THEME_COLORS } from '../../utils/Constant';
import { ArticleDetailsLoader } from '../../components/ContentLoader';


function ArticleDetials(props) {
    const location = useLocation();
    const slug = props.match.params.slug;
    const [article, setArticle] = useState(null);
    const [relatedArticles, setRelatedArticles] = useState([]);
    useEffect(() => {
        getArticles('?slug='+slug+'&related=true').then((result) => {
            const article = result.data.data;
            setArticle(article);
            if (article?.related) {
                setRelatedArticles(article?.related);
            }
        });
    }, [location]);
    if (article === null) {
        return(
            <ArticleDetailsLoader/>
        );
    }
    return (
        <div className="article-detail-page">
            <Container>
                <Container className="small-container">
                    <div className="article-header-container">
                        <div className="article-tags-container">
                            <div className="article-tag">{article.tags?.[props?.APP_LOCALE]}</div>
                            <div className="article-day">{moment(article.created).format('LL')}</div>
                        </div>
                        <div className="article-title">
                            <h2>{article?.title?.[props?.APP_LOCALE]}</h2>
                        </div>
                        <div className="social-container">
                            <SocialMedia/>
                        </div>
                    </div>
                    <div className="article-img-container">
                        <img src={article.cover_picture.base_url+article.cover_picture.files['900X550']} alt={article?.title?.[props?.APP_LOCALE]}/>
                        {/* <div className="img-text">
                            Image Text Description
                        </div> */}
                    </div>
                    <div className="article-content-container" dangerouslySetInnerHTML={{__html: article?.content?.[props?.APP_LOCALE]}}>
                    </div>
                </Container>
            </Container>
            <ArticlesSection items={relatedArticles} {...props}/>
        </div>
    )
}

const ArticlesSection = (props) => {
    const articles = props.items || [];
    const articleSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        swipeToSlide: true,
        adaptiveHeight: false,
        responsive: [
            {
              breakpoint: 575.98,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
        ],
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        appendDots: dots => (<div><ul style={{ margin: "0px" }}>{dots}</ul></div>),
        customPaging: i => (<div className="custom-dots"></div>)
    };
    const { t } = useTranslation(['blog']);
    const WIDTH = window.innerWidth;
    return (
        <div style={{paddingTop: '0px', paddingBottom: '300px', backgroundColor: props.THEME === 'light' ? '#fff' : '#101010', position: 'relative'}} className="article-container">
            <WaveContainer  
                fill={THEME_COLORS?.[props.THEME].wave.MAIN_BACKGROUND}
                paused={false}
            />
            <Container className="related-article-container">
                <h3>{t('blog:related_articles')}</h3>
                <Row>
                    <Col className="related-article-card-container" lg={12} style={{marginLeft: WIDTH > 575.98 && -15, marginRight: -15}}>
                        <div className="arrows-bottom">
                            <Slider {...articleSettings}>
                                {articles.map((item, index)=> (
                                    <Col key={index.toString()}>
                                        <ArticleCard
                                            tag={''}
                                            image={item.cover_picture.base_url+item.cover_picture.files['500X500']}
                                            title={item?.title?.[props?.APP_LOCALE]}
                                            slug={item.slug}/>
                                    </Col>
                                ))}
                            </Slider>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export function NextArrow(props) {
    const { style, onClick } = props;
    return (
        <div className="slick-arrow-next" style={{ ...style, display: "flex"}} onClick={onClick}>
            <FontAwesomeIcon className="carousal-icon" icon={faChevronRight}/>
        </div>
    );
}

export function PrevArrow(props) {
    const { style, onClick } = props;
    return (
        <div className="slick-arrow-prev" style={{ ...style, display: "flex"}} onClick={onClick}>
            <FontAwesomeIcon className="carousal-icon" icon={faChevronLeft}/>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        APP_LOCALE: state.app.app_locale,
        IS_RTL: state.app.is_rtl,
        THEME: state.app.theme
    }
}

export default connect(mapStateToProps, null)(ArticleDetials);
