import React from 'react'
import './Banner.scss';

export default function Banner(props) {
    return (
        <div onClick={props.onClick ? props.onClick : null} className="banner-container">
            <img src={props.image} alt={props.title}/>
            <div className="banner-overlay-container">
                <h5>{props.title}</h5>
                <h6>{props.info}</h6>
            </div>
        </div>
    )
}
