export const THEME_COLORS = {
    light: {
        wave: {
            GOLD : 'rgba(244, 200, 94,',
            PINK : 'rgba(244, 114, 92,',
            BACKGROUND : 'rgba(232, 232, 232,', // for #e8e8e8
            BACKGROUND_ASSISTIVE_TEMP : 'rgba(232, 232, 232,', // for #e8e8e8
            FOOTER : 'rgba(232, 232, 232,', // for #E8E8E8
            BACKGROUND2 : 'rgba(247, 247, 247,', // for #f7f7f7
            // BACKGROUND3 : 'rgba(232, 232, 232,', // for #E8E8E8
            BACKGROUND3 : 'rgba(232, 232, 232,', // for #E8E8E8
            MAIN_BACKGROUND : 'rgba(255, 255, 255,', // #ffffff
            BACKGROUND_DOWNLOAD_TEMP : 'rgba(255, 255, 255,', // #ffffff
            TESTIMONIAL_BG : 'rgba(158, 194, 187,'
        },
        color: {
            BG_COLOR: '#e8e8e8',
            BG_COLOR2: '#e8e8e8',
            GOLD: '#F4C85E',
            PINK : '#F4725C',
            TESTIMONIAL_BG : '#9EC2BB',
            WHITE : 'rgba(0,0,0,1)',
            WHITE50 : 'rgba(0,0,0,0.5)',
            MAIN_BACKGROUND : '#ffffff',
            COUNTRY_NAV_LOADING_COLOR: '#000000',
        }
    },
    dark: {
        wave: {
            GOLD : 'rgba(244, 200, 94,', // for #F4C85E
            PINK : 'rgba(244, 114, 92,', // for #F4725C
            BACKGROUND : 'rgba(0, 0, 0,', //for #000000
            // BACKGROUND : 'rgba(14, 14, 14,', //for #0E0E0E
            BACKGROUND_ASSISTIVE_TEMP : 'rgba(16, 16, 16,', //for ##101010
            // BACKGROUND_ASSISTIVE_TEMP : 'rgba(20, 20, 20,', //for #141414
            BACKGROUND2 : 'rgba(0, 0, 0,', //for #000000
            // BACKGROUND2 : 'rgba(10, 10, 10,', //for #0a0a0a
            FOOTER : 'rgba(0, 0, 0,', //for #0a0a0a
            // FOOTER : 'rgba(10, 10, 10,', //for #0a0a0a
            BACKGROUND3 : 'rgba(36, 36, 36,', //for #242424
            MAIN_BACKGROUND : 'rgba(16, 16, 16,', // for ##101010
            // MAIN_BACKGROUND : 'rgba(20, 20, 20,', // for #141414
            BACKGROUND_DOWNLOAD_TEMP : 'rgba(0, 0, 0,', // for #141414
            TESTIMONIAL_BG : 'rgba(244, 200, 94,'
        },
        color: {
            BG_COLOR: '#000000',
            // BG_COLOR: '#0a0a0a',
            // BG_COLOR2: '#0E0E0E',
            BG_COLOR2: '#000000',
            GOLD: '#F4C85E',
            PINK : '#F4725C',
            TESTIMONIAL_BG : '#F4C85E',
            WHITE : 'rgba(255,255,255,1)',
            WHITE50 : 'rgba(255,255,255,0.5)',
            // MAIN_BACKGROUND : '#141414',
            MAIN_BACKGROUND : '#101010',
            COUNTRY_NAV_LOADING_COLOR: '#ffffff'
        }
    }
}
