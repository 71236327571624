import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, Card, Col, Container, Row } from 'react-bootstrap';
import { IMG_FAQ_BG, IMG_CONTACT, IMG_CONTACT_LIGHT } from './../../assets/images/Index';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { DownloadBanner } from '../Home/Home';
import { THEME_COLORS } from './../../utils/Constant';
import { connect } from 'react-redux';
import { getFaqList } from './../../service/Api/ApiRequest';
import FontIcon from '../../components/FontIcon/FontIcon';
import Btn from '../../components/Btn/Btn';
import WaveContainer from '../../components/wave/WaveContainer';
import { FaqLoader } from '../../components/ContentLoader';
import './Faq.scss';
import './FaqResponsive.scss';

function Faq(props) {
    const { t } = useTranslation(['common']);
    return (
        <div className="faq-page-container">
            <Header 
                background={IMG_FAQ_BG.default}
                title={t('common:faqs')}
                {...props}
            />
            <MainBody {...props}/>
        </div>
    )
}

const MainBody = (props) => {
    const { t } = useTranslation(['common']);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getFaqList('?group_by=category').then((result) => {
            const items = result.data.data || [];
            setItems(items);
            setLoading(false);
        });
    }, []);
    if (true === loading) {
        return(
            <Container fluid className="faq-page-content-container">
                <Container style={{paddingBottom: '250px', marginTop: '105px'}}>
                    <FaqLoader/>
                </Container>
            </Container>
        );
    }
    return (
        <Container fluid className="faq-page-content-container">
            <Container style={{paddingBottom: '250px'}}>
                {items.map((item,index) => {
                    return(
                        <Row key={index.toString()} className="faq-row">
                            <Col lg={4} className="faq-title-container">
                                <div className="faq-title-border" style={{backgroundColor: '#9EC2BB'}}/>
                                <h3>
                                    {t('common:'+item.id)}
                                </h3>
                            </Col>
                            <Col lg={8} className="faq-container">
                                <Accordion defaultActiveKey="0">
                                    {item.data.map((faq, index)=> {
                                        return(
                                            <Card key={index.toString()}>
                                                <Accordion.Toggle as={Card.Header} eventKey={index+1}>
                                                    <h6>{faq.question?.[props.APP_LOCALE]}</h6>
                                                    <FontIcon icon={faPlus}/>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey={index+1}>
                                                    <Card.Body>
                                                        <p>{faq.answer?.[props.APP_LOCALE]}</p>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        )
                                    })}
                                </Accordion>
                            </Col>
                        </Row>
                    );
                })}
            </Container>
            <AssistiveBanner 
                image={props.THEME === 'light' ? IMG_CONTACT_LIGHT.default : IMG_CONTACT.default}
                title={t('common:faq.didnt_find_what_you_were_looking_for')}
                reverse={true}
                btnTitle={t('common:faq.lets_chat')}
                to={'/contact'}
                {...props}
            />
            <Container fluid style={{paddingTop: 0, paddingBottom: '250px', position: 'relative'}}>
                <WaveContainer 
                    fill={THEME_COLORS?.[props.THEME].wave.BACKGROUND_DOWNLOAD_TEMP}
                    paused={false}
                />
                <Container className="download-banner-wrapper">
                    <DownloadBanner inverse/>
                </Container>
            </Container>
        </Container>
    )
}

export const Header = (props) => {
    return (
        <Container fluid className="header-container" style={{backgroundImage: `url(${props.background})`}}>
            <Container className="header-content-container">
                <h1>{props.title}</h1>
                {props.para &&
                    <p>{props.para}</p>
                }
            </Container>
        </Container>
    )
}

export const AssistiveBanner = (props) => {
    return(
        <Container fluid className="assistive-banner-wrapper">
            {props.wave
                ?
                props.wave
                :
                <WaveContainer 
                    fill={THEME_COLORS?.[props.THEME].wave.BACKGROUND_ASSISTIVE_TEMP}
                    paused={false}
                />

            }
            <Container>
                <Row className={`assistive-banner-container ${props.reverse ? 'flex-row-reverse' : null}`}>
                {/* <Row className="assistive-banner-container flex-row-reverse"> */}
                    <Col  lg={props.reverse ? 5 : 6}>
                        <img alt={props.title} src={props.image}/>
                    </Col>
                    <Col lg={props.reverse ? 7 : 6} className="assistive-banner-content-container">
                        <h4 className="new-line title-primary">
                            {props.title}
                        </h4>
                        <div>
                            <Btn to={props.to} title={props.btnTitle}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        APP_LOCALE: state.app.app_locale,
        IS_RTL: state.app.is_rtl,
        THEME: state.app.theme
    }
}

export default connect(mapStateToProps, null)(Faq);