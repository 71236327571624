import React from 'react';
import './Page404.scss';
import './Page404Responsive.scss';
import FontIcon from '../../components/FontIcon/FontIcon';

import { IMG_404, IMG_404_ILLUSTRATION, IMG_404_ILLUSTRATION_LIGHT, IMG_404_LIGHT } from './../../assets/images/Index'
import { Link } from 'react-router-dom'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';

function Page404(props) {
    return (
        <Container fluid className="not-found-container">
            <Container>
                <div className="four-not-four-container">
                    <img src={props.THEME === 'light' ? IMG_404_LIGHT.default : IMG_404.default } alt={'404'}/>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '40px'}}>
                        <Link to="/">
                            <FontIcon icon={faArrowLeft}/>
                            Home Page
                        </Link>
                    </div>
                    <div className="four-not-four-illustration">
                        <img src={props.THEME === 'light' ? IMG_404_ILLUSTRATION_LIGHT.default : IMG_404_ILLUSTRATION.default} alt={'404 illustration'}/>
                    </div>
                </div>
            </Container>
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        APP_LOCALE: state.app.app_locale,
        IS_RTL: state.app.is_rtl,
        THEME: state.app.theme
    }
}

export default connect(mapStateToProps, null)(Page404);
