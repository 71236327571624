import React from 'react'

export default function CategoryCard(props) {
    return (
        <div onClick={props.onClick} className="category-card">
            <div className="img-container">
                <img className="chevron-bottom" src={props.image} alt={props.title}/>
            </div>
            <div className="title-container">
                <span className="title">{props.title}</span>
            </div>
        </div>
    )
}
