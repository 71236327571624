import firebase from 'firebase/app';
import 'firebase/messaging';
import 'firebase/database';
import 'firebase/analytics';

var firebaseConfig = {
    apiKey: "AIzaSyDjwBIjiEUhJuwd3S1iXDWN5FGvXsH0e8Y",
    authDomain: "walsos.firebaseapp.com",
    databaseURL: "https://walsos.firebaseio.com",
    projectId: "walsos",
    storageBucket: "walsos.appspot.com",
    messagingSenderId: "344284706288",
    appId: "1:344284706288:web:c08cd14e4461e305331935",
    measurementId: "G-75LHTM3X84"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;

export { firebase, messaging }
