import './Articles.scss';
import './ArticlesResponsive.scss';
import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom'; 
import { connect } from 'react-redux';
import queryString from 'query-string';
import moment from 'moment';
import ArticleCard from '../../components/ArticleCard/ArticleCard';
import { Header } from '../Faq/Faq'
import { IMG_ARTICLE_BG } from './../../assets/images/Index';
import { getArticles } from './../../service/Api/ApiRequest';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { RoundedBtn } from '../../utils/UIComponent/UIComponent';
import { ArticlesLoader } from '../../components/ContentLoader';
import { DownloadBanner } from '../Home/Home';
import { THEME_COLORS } from './../../utils/Constant';
import WaveContainer from '../../components/wave/WaveContainer';

const HeaderView = (props) => {
    const { t } = useTranslation(['blog']);
    return(
        <div className="article-page">
            <Header background={IMG_ARTICLE_BG.default} title={t('blog:blog')} />
            <div className="article-body">
                <Container style={{position: 'relative', paddingBottom: '250px'}}>
                    <Row>
                        {props.children}
                    </Row>
                </Container>
                <Container fluid className="download-wrapper">
                    <WaveContainer 
                        fill={THEME_COLORS?.[props.THEME]?.wave.BACKGROUND_ASSISTIVE_TEMP}
                        paused={false}
                    />
                    <Container>
                        <DownloadBanner inverse/>
                    </Container>
            </Container>
            </div>
        </div>
    );
}

const LoadingView = (props) => (
    <HeaderView>
        <ArticlesLoader/>
    </HeaderView>
);

const EmptyView = () => (
    <HeaderView>
        <Col lg={12}>
            
        </Col>
    </HeaderView>
);


function Articles(props) {
    const history = useHistory();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const queryParamsPage = parseInt(queryParams?.page) || 1;
    const [loading, setLoading] = useState(true);
    const [articles, setArticles] = useState([]);
    const [firstArticle, setFirstArticle] = useState(null);
    const [secondArticle, setSecondArticle] = useState(null);
    const [thirdArticle, setThirdArticle] = useState(null);
    const [hasPrevPage, setHasPrevPage] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(false);
    let paginationBtnAlignmentClass = 'pagination-btn-container';
    if (true === hasPrevPage && false === hasNextPage) {
        paginationBtnAlignmentClass += ' pagination-flex-start';
    }
    if (false === hasPrevPage && true === hasNextPage) {
        paginationBtnAlignmentClass += ' pagination-flex-end';
    }
    useEffect(() => {
        getArticles('?summary=true&limit=15&page='+queryParamsPage).then((result) => {
            const articles = result?.data?.data || [];
            const resultParams = result?.data?.params || {};
            if (articles.length > 0) {
                setFirstArticle(articles[0]);
            }
            if (articles.length > 1) {
                setSecondArticle(articles[1]);
            }
            if (articles.length > 2) {
                setThirdArticle(articles[2]);
            }
            setArticles(articles.slice(3, articles.length));
            setHasPrevPage(resultParams?.has_prev_page);
            setHasNextPage(resultParams?.has_next_page);
            setLoading(false);
        });
    }, []);
    const didPressPaginationNextBtn = () => {
        setLoading(true);
        const nextPage = queryParamsPage + 1;
        history.push("/blog?page="+nextPage);
    }
    const didPressPaginationPrevBtn = () => {
        setLoading(true);
        const prevPage = queryParamsPage - 1;
        history.push("/blog?page="+prevPage);
    }
    if (null === articles) {
        return <EmptyView/>
    }
    if (true === loading) {
        return <LoadingView theme={props?.THEME}/>
    }
    const firstArticleTitle = firstArticle?.title?.[props?.APP_LOCALE] || '';
    const secondArticleTitle = secondArticle?.title?.[props?.APP_LOCALE] || '';
    const thirdArticleTitle = thirdArticle?.title?.[props?.APP_LOCALE] || '';
    const firstArticleExcerpt = firstArticle?.description?.[props?.APP_LOCALE] || '';
    return (
        <HeaderView {...props}>
            {null !== firstArticle &&
                <Col lg={12}>
                    <ArticleCard
                        first
                        date={moment(firstArticle.created).format('LL')}
                        tag={firstArticle?.tags?.[props?.APP_LOCALE] || ''}
                        image={firstArticle.cover_picture.base_url+firstArticle.cover_picture.files['900X550']}
                        title={firstArticleTitle.length > 62 ? firstArticleTitle.substr(0, 62)+'...' : firstArticleTitle}
                        // excerpt={firstArticle?.description?.[props?.APP_LOCALE] || ''}
                        excerpt={firstArticleExcerpt.length > 120 ? firstArticleExcerpt.substr(0, 120)+'...' : firstArticleExcerpt}
                        slug={firstArticle?.slug || ''}
                    />
                </Col>
            }
            {null !== secondArticle &&
                <Col lg={5}>
                    <ArticleCard
                        date={moment(secondArticle.created).format('LL')}
                        tag={secondArticle?.tags?.[props?.APP_LOCALE] || ''}
                        image={secondArticle.cover_picture.base_url+secondArticle.cover_picture.files['900X550']}
                        title={secondArticleTitle.length > 65 ? secondArticleTitle.substr(0, 65)+'...' : secondArticleTitle}
                        excerpt={secondArticle?.description?.[props?.APP_LOCALE] || ''}
                        slug={secondArticle?.slug || ''}
                    />
                </Col>
            }
            {null !== thirdArticle &&
                <Col lg={7}>
                    <ArticleCard
                        date={moment(thirdArticle.created).format('LL')}
                        tag={thirdArticle?.tags?.[props?.APP_LOCALE] || ''}
                        image={thirdArticle.cover_picture.base_url+thirdArticle.cover_picture.files['900X550']}
                        title={thirdArticleTitle.length > 90 ? thirdArticleTitle.substr(0, 90)+'...' : thirdArticleTitle}
                        excerpt={thirdArticle?.description?.[props?.APP_LOCALE] || ''}
                        slug={thirdArticle?.slug || ''}
                    />
                </Col>
            }
            {articles.map((article, index)=> {
                const title = article?.title?.[props?.APP_LOCALE] || '';
                return(
                    <Fragment key={index.toString()}>
                        <Col lg={6}>
                            <ArticleCard
                                date={moment(article.created).format('LL')}
                                tag={article?.tags?.[props?.APP_LOCALE] || ''}
                                image={article.cover_picture.base_url+article.cover_picture.files['900X550']}
                                title={title.length > 70 ? title.substr(0, 70) + '...' : title}
                                excerpt={article?.description?.[props?.APP_LOCALE] || ''}
                                slug={article?.slug || ''}
                            />
                        </Col>
                    </Fragment>
                );
            })}
            <div className={paginationBtnAlignmentClass}>
                {true === hasPrevPage &&
                    <RoundedBtn icon={props.IS_RTL ? faChevronRight : faChevronLeft} prev onClick={didPressPaginationPrevBtn}/>
                }
                {true === hasNextPage &&
                    <RoundedBtn icon={props.IS_RTL ? faChevronLeft : faChevronRight} onClick={didPressPaginationNextBtn}/>
                }
            </div>
        </HeaderView>
    );
}

const mapStateToProps = state => {
    return {
        APP_LOCALE: state.app.app_locale,
        IS_RTL: state.app.is_rtl,
        THEME: state.app.theme
    }
}

export default connect(mapStateToProps, null)(Articles);