import {
    SET_LOCALE,
    SET_DEVICE_COUNTRY,
    SET_THEME,
    DEVICE_SYNCED
} from './types';

export const setLocale = (locale) => {
    return {
        type: SET_LOCALE,
        payload: locale
    }
};

export const setDeviceCountry = (country) => {
    return {
        type: SET_DEVICE_COUNTRY,
        payload: country
    }
};

export const setTheme = (theme) => {
    return {
        type: SET_THEME,
        payload: theme
    }
};

export const deviceSynced = (synced) => {
    return {
        type: DEVICE_SYNCED,
        payload: synced
    }
};