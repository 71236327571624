import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from "redux";
import { firebase, messaging } from '../firebase.js';
import { deviceInfo } from './../utils/Function';
import { syncDeviceInfo } from './../service/Api/ApiRequest';
import Navbar from './NavigationBar/NavigationBar';
import Footer from './Footer/Footer';
import { setLocale, deviceSynced } from './../redux/actions/app';
import i18n from './../i18n';

function RootComponent(props) {

    const syncDevice = () => {
        if (firebase.messaging.isSupported()) {
            messaging.getToken().then((token) => {
                if (token) {
                    deviceInfo((result) => {
                        const DEVICE_DATA = result;
                        DEVICE_DATA.fcm_token = token;
                        syncDeviceInfo(DEVICE_DATA).then(function (response) {
                            props.deviceSynced(true);
                        });
                    });
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
            });
        }
    }

    const registerPresence = () => {
        deviceInfo((result) => {
            const DEVICE_DATA = result;
            const MODEL = DEVICE_DATA.device_option.model;
            const USER_ID = DEVICE_DATA.device_identifier;
            const USER_STATUS_REF = firebase.database().ref('/online/'+USER_ID);
            const IS_ONLINE = {
                status: 'online',
                id: '-',
                role: 'GUEST',
                name: {en: MODEL, ar: MODEL},
                updated_at: firebase.database.ServerValue.TIMESTAMP,
            };
            firebase.database().ref('/online/' + USER_ID).set(null);
            firebase.database().ref('.info/connected').on('value', (snapshot) => {
                if (snapshot.val() === false) {
                    return;
                };
                USER_STATUS_REF.onDisconnect().set(null).then(() => {
                    USER_STATUS_REF.set(IS_ONLINE);
                });
            });
        });
    }

    useEffect(() => {
        if (null === props.APP_LOCALE) {
            props.setLocale(i18n.language);
        }
        if (null === props.DEVICE_SYNCED) {
            syncDevice();
        }
        registerPresence();
        document.body.classList.add('app-theme-'+props.THEME);
        const unlisten = props.history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        }
    });
    const childrens = React.Children.map(props.children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {test: 'abc'});
        }
    });
    return (
        <div>
            <Navbar/>
                {childrens}
            <Footer/>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        APP_LOCALE: state.app.app_locale,
        IS_RTL: state.app.is_rtl,
        THEME: state.app.theme,
        DEVICE_SYNCED: state.app.device_synced
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setLocale: (data) => dispatch(setLocale(data)),
        deviceSynced: (data) => dispatch(deviceSynced(data))
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(RootComponent);
