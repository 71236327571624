import '../../App.scss';
import './contact.scss';
import './ContactResponsive.scss';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Col, Container, Form, Row, Button } from 'react-bootstrap';
import { Formik } from "formik";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import * as Yup from "yup";
import { Header, AssistiveBanner } from '../Faq/Faq';
import { DownloadBanner } from '../Home/Home';
import { faPhone, faEnvelope, faMapMarker } from '@fortawesome/free-solid-svg-icons';
import { THEME_COLORS } from './../../utils/Constant';
import { SocialMedia } from '../Session/Session';
import { IMG_CONTACT_BG, IMG_CONTACT, IMG_CONTACT_LIGHT, ICON_GOLDEN_RIGHT_ARROW, IMG_FAQ, IMG_FAQ_LIGHT } from './../../assets/images/Index';
import FontIcon from '../../components/FontIcon/FontIcon';
import WaveContainer from '../../components/wave/WaveContainer';
import { contactMessage } from './../../service/Api/ApiRequest';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Contact(props) {
    const { t } = useTranslation(['contact']);
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, t("contact:name_must_have_at_least_2_characters"))
            .max(100, t("contact:name_cant_be_longer_than_100_characters"))
            .required(t("contact:name_is_required")),
        email: Yup.string()
            .email(t("contact:must_be_a_valid_email_address"))
            .max(100, t("contact:email_must_be_less_than_100_characters"))
            .required(t("contact:email_is_required")),
        subject: Yup.string()
            .min(5, t("contact:subject_must_have_at_least_5_characters"))
            .max(100, t("contact:subject_cant_be_longer_than_100_characters"))
            .required(t("contact:subject_is_required")),
        message: Yup.string()
            .min(10, t("contact:message_must_have_at_least_10_characters"))
            .max(10000, t("contact:message_cant_be_longer_than_10000_characters"))
            .required(t("contact:message_is_required"))
    });
    const [displaySnackbar, setDisplaySnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const hideSnackbar = () => {
        setDisplaySnackbar(false);
        setSnackbarMessage('');
    }
    return (
        <div className="contact-page">
            <Header background={IMG_CONTACT_BG.default} title={t('contact:contact_us')}/>
            <Container fluid className="contact-form-container">
                <Container>
                    <h4 className="title-primary">{t('contact:get_in_touch')}</h4>
                    <Row>
                        <Col lg={6} className="form-container">
                            <Formik
                                initialValues={{name:"", email:"", subject:"", message:""}}
                                validationSchema={validationSchema}
                                onSubmit={(values, {setSubmitting, resetForm}) => {
                                    setSubmitting(true);
                                    const FORM_VALUES = {...values};
                                    FORM_VALUES.source = 'web';
                                    contactMessage(FORM_VALUES).then((response) => {
                                        setDisplaySnackbar(true);
                                        setSnackbarMessage(t('contact:thank_you_for_reaching_out_our_team_will_revert_shortly_on_this'));
                                        resetForm();
                                        setSubmitting(false);
                                    });
                                }}
                            >
                                {({values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
                                    return(
                                        <Form noValidate onSubmit={handleSubmit}>
                                            <Form.Group controlId="formName">
                                                <Form.Control
                                                    required size="lg"
                                                    type="text"
                                                    name="name"
                                                    placeholder={t("contact:name")}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.name}
                                                    className={touched.name && errors.name ? "error" : null}
                                                />
                                                {touched.name && errors.name ? (
                                                    <div className="error-message">{errors.name}</div>
                                                ): null}
                                            </Form.Group>
                                            <Form.Group controlId="formEmail">
                                                <Form.Control
                                                    size="lg"
                                                    type="text"
                                                    name="email"
                                                    placeholder={t("email_address")}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.email}
                                                    className={touched.email && errors.email ? "error" : null}
                                                />
                                                {touched.email && errors.email ? (
                                                    <div className="error-message">{errors.email}</div>
                                                ): null}
                                            </Form.Group>
                                            <Form.Group controlId="formSubject">
                                                <Form.Control
                                                    size="lg"
                                                    type="text"
                                                    name="subject"
                                                    placeholder={t("contact:subject")}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.subject}
                                                    className={touched.subject && errors.subject ? "error" : null}
                                                />
                                                {touched.subject && errors.subject ? (
                                                    <div className="error-message">{errors.subject}</div>
                                                ): null}
                                            </Form.Group>
                                            <Form.Group controlId="formMessage">
                                                <Form.Control
                                                    size="lg"
                                                    as="textarea"
                                                    name="message"
                                                    rows={8}
                                                    placeholder={t("contact:message")}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.message}
                                                    className={touched.message && errors.message ? "error" : null}
                                                />
                                                {touched.message && errors.message ? (
                                                    <div className="error-message">{errors.message}</div>
                                                ): null}
                                            </Form.Group>
                                            <div>
                                                {/* <Btn title={'Send'} onClick={didSubmitBtn}/> */}
                                                <Button className="btn-container" type="submit" disabled={isSubmitting}>
                                                    {isSubmitting ? t('contact:please_wait') : t('contact:send')}
                                                </Button>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </Col>
                        <Col lg-offset="1" lg={5} className="address-container">
                            <div className="address-content-container">
                                <span>
                                    <FontIcon icon={faPhone}/>
                                </span>
                                <label>
                                    <span><a href={"tel:+965 60031166"}>+965 60031166</a></span> / <span><a href={"tel:+965 22067060"}>+965 22067060</a></span>
                                </label>
                            </div>
                            <div target="_blank" rel="noreferrer" className="address-content-container">
                                <span>
                                    <FontIcon icon={faEnvelope}/>
                                </span>
                                <label>
                                    <span><a href={"mailto:gm@walsos.com"}>gm@walsos.com</a></span> / <span><a href={"mailto:it@walsos.com"}>it@walsos.com</a></span>
                                </label>
                            </div>
                            <a href={'https://maps.app.goo.gl/tTY4BaCUZaNLLsuM7'} target="_blank" rel="noreferrer" className="address-content-container">
                                <span>
                                    <FontIcon icon={faMapMarker}/>
                                </span>
                                <label>
                                    {props.IS_RTL ? 'شركة وال سوس للاستشارات عبر الانترنت‏ ضاحية صباح السالم، قطعه ١، أبراج بافاريا برج D، الدور ١، مكتب ٣' : 'WALSOS Company For General Trading block 2 rd 5 bavaria tower D first floor office C sabah al salim, 28268 6708 0007'}
                                </label>
                            </a>
                            <SocialMedia onClick={item => window.open(item.url, "_blank")}/>
                            <img alt="Contact Us" src={props.THEME === 'light' ? IMG_CONTACT_LIGHT.default : IMG_CONTACT.default}/>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <AssistiveBanner
                image={props.THEME === 'light' ? IMG_FAQ_LIGHT.default : IMG_FAQ.default}
                title={t('contact:need_quick_assistance') + '\n' + t('contact:check_frequently_asked_questions')}
                reverse={false}
                btnTitle={<img alt="FAQ" className="golden-right-arrow" src={ICON_GOLDEN_RIGHT_ARROW.default}/>}
                {...props}
                to={'/faq'}
            />
            <Container fluid className="download-wrapper">
                <WaveContainer 
                    fill={THEME_COLORS?.[props.THEME]?.wave.BACKGROUND_DOWNLOAD_TEMP}
                    paused={false}
                />
                <Container>
                    <DownloadBanner inverse/>
                </Container>
            </Container>
            <Snackbar open={displaySnackbar} autoHideDuration={6000} onClose={hideSnackbar} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
                <Alert onClose={hideSnackbar} severity="success">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        APP_LOCALE: state.app.app_locale,
        IS_RTL: state.app.is_rtl,
        THEME: state.app.theme
    }
}

export default connect(mapStateToProps, null)(Contact);