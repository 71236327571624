import EventEmitter3 from 'eventemitter3';

export default class EventEmitter {

    static instance = null;

    static getInstance() {
        if (EventEmitter.instance == null) {
            EventEmitter.instance = new EventEmitter3();
        }
        return this.instance;
    }
}
