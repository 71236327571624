import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { PrivacyLoader } from '../../components/ContentLoader';
import { TitleHighlited } from '../PrivacyPolicy/PrivacyPolicy';
import { termsAndConditions } from './../../service/Api/ApiRequest';

function Terms(props) {
    const { t } = useTranslation(['common']);
    const [data, setData] = useState('');
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        termsAndConditions().then((result) => {
            setLoading(false);
            setData(result.data.data);
        });
    }, []);
    return (
        <>
            <TitleHighlited title={t('common:terms_of')} highlited={t('common:service')}/>
            {   loading === false ?
                <Container style={{paddingBottom: '200px', paddingTop: '60px'}}>
                    <div className="para-black-container" dangerouslySetInnerHTML={{__html: data?.[props.APP_LOCALE]}}></div>
                </Container>
                :
                <PrivacyLoader/>
            }
        </>
    )
}

const mapStateToProps = state => {
    return {
        APP_LOCALE: state.app.app_locale,
        IS_RTL: state.app.is_rtl,
        THEME: state.app.theme
    }
}

export default connect(mapStateToProps, null)(Terms);