import '../../App.scss';
import './search.scss';
import './SearchResponsive.scss';
import React, { useState, useEffect } from 'react';
import { Container, InputGroup, FormControl, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { faChevronLeft, faChevronRight, faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Slider from "react-slick";
import EventEmitter from './../../service/EventEmitter';
import ExpertCard from '../../components/ExpertCard/ExpertCard';
import SessionCard from '../../components/SessionCard/SessionCard';
import CategoryCard from '../../components/CategoryCard/CategoryCard';
import FontIcon from '../../components/FontIcon/FontIcon';
import { IMG_SEARCH_BG, ICON_CHEVRON_BOTTOM } from '../../assets/images/Index';
import { getExpertList, getCategoryList, getSessionList } from '../../service/Api/ApiRequest';
import { CategoryFilterLoader, FeaturedExpertLoader, FeaturedSessionLoader } from '../../components/ContentLoader';
import { SearchNotFound } from '../../utils/UIComponent/UIComponent';

const _event = EventEmitter.getInstance();

const CategorySettings = {
    dots: false,
    infinite: true,
    speed: 500,
    swipeToSlide: true,
    variableWidth: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    // responsive: [
    //     {
    //       breakpoint: 575.98,
    //       settings: {
    //         slidesToShow: 2.7,
    //         // slidesToScroll: 1,
    //         rows: 4,
    //         infinite: false,
    //       }
    //     }
    // ],
};

const SessionSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    swipeToSlide: true,
    rows: 3,
    arrows: true,
    mobileFirst: true,
    responsive: [
        {
          breakpoint: 575.98,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: dots => (<div><ul style={{ margin: "0px" }}> {dots} </ul></div>),
    customPaging: i => (<div className="custom-dots"></div>)
};
const ExpertSettings = {
    dots: false,
    infinite: false,
    autoplay: false,
    speed: 500,
    // variableWidth: true,
    swipeToSlide: true,
    arrows: true,
    mobileFirst: true,
    slidesToShow: 6.05,
    responsive: [
        {
          breakpoint: 575.98,
          settings: {
            slidesToShow: 1.9,
            // slidesToScroll: 1,
            rows: 2,
          }
        }
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
};


function SearchMain(props) {
    const { t } = useTranslation(['search']);
    const history = useHistory();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const queryParamsKeyword = queryParams?.q || '';
    const [searchText, setSearchText] = useState(queryParamsKeyword);
    const [categories, setCategories] = useState([]);
    const [loadingCategories, setLoadingCategories] = useState(true);
    const [experts, setExperts] = useState([]);
    const [loadingExperts, setLoadingExperts] = useState(true);
    const [sessions, setSessions] = useState([]);
    const [loadingSessions, setLoadingSessions] = useState(true);
    const _getCategoryList = (keyword = '') => {
        getCategoryList('?sort_by=booking_count&sort_order=desc&limit=20&keyword='+keyword).then((categoryResult) => {
            setCategories(categoryResult.data.data || []);
            setLoadingCategories(false);
        });
    }
    const _getExpertList = (countryCode, keyword = '') => {
        getExpertList('?sort_by=id&sort_order=desc&limit=20&region='+countryCode+'&keyword='+keyword).then((expertResult) => {
            setExperts(expertResult.data.data || []);
            setLoadingExperts(false);
        });
    }
    const _getSessionList = (countryCode, keyword = '') => {
        getSessionList('?sort_by=booking_count&sort_order=desc&limit=20&region='+countryCode+'&keyword='+keyword).then((sessionResult) => {
            setSessions(sessionResult.data.data || []);
            setLoadingSessions(false);
        });
    }
    useEffect(() => {
        _getCategoryList(searchText);
        _getExpertList(props.DEVICE_COUNTRY_CODE, searchText);
        _getSessionList(props.DEVICE_COUNTRY_CODE, searchText);
        _event.on('app.region_change', (params) => {
            setLoadingExperts(true);
            setLoadingSessions(true);
            _getExpertList(params?.data?.country_code, searchText);
            _getSessionList(params?.data?.country_code, searchText);
        });
    }, []);
    const onUserSearch = (event) => {
        const text = event.currentTarget.value;
        setSearchText(text);
        setLoadingCategories(true);
        setLoadingExperts(true);
        setLoadingSessions(true);
        _getCategoryList(text);
        _getExpertList(props.DEVICE_COUNTRY_CODE, text);
        _getSessionList(props.DEVICE_COUNTRY_CODE, text);
    }
    const didPressCategoryItem = (item) => {
        history.push("/category/"+item.slug);
    }
    const didPressViewAllExperts = () => {
        history.push("/search/experts");
    }
    const didPressViewAllSessions = () => {
        history.push("/search/sessions");
    }
    const didPressViewAllCategories = () => {
        history.push("/search/categories");
    }
    // document.title = "Search Video Session With Experts"
    return (
        <div className="searchpage-container search-main-container">
            <Container fluid className="search-bg">
                <div className="search-img-absolute-container">
                    <div style={{ height: '100%', width: '100%',position: 'relative'}}>
                        <img className="absolute-searcg-bg" src={IMG_SEARCH_BG.default} alt={''}/>
                    </div>
                </div>
                <Container className="search-container">
                    <div className="search-content-container">
                        <h2 className="title-primary">{t('search:page_title')}</h2>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                    <FontIcon className="search-icon" icon={faSearch}/>
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder={t('search:search_expert_or_session')}
                                aria-label="Search expert or session"
                                aria-describedby="basic-addon1"
                                value={searchText}
                                onChange={onUserSearch}
                            />
                        </InputGroup>
                    </div>
                </Container>
            </Container>
            <Container fluid className="category-section">
                {/* <WaveContainer 
                    fill={THEME_COLORS?.[props.THEME].wave.BACKGROUND}
                    paused={true}
                /> */}
                <Container className="category-content-container">
                    <div className="main-search-category-container">
                        {(true !== loadingCategories && categories.length > 0) &&
                            <div className="category-header-row">
                                <div>
                                    <h3>{t('search:categories')}</h3>
                                </div>
                                <div>
                                    <button className="grey-btn" onClick={didPressViewAllCategories}>
                                        <span className="btn-text">{t('search:view_all')}</span>
                                        <img alt={t('search:view_all')} className="chevron-bottom" src={ICON_CHEVRON_BOTTOM.default}/>
                                    </button>
                                </div>
                            </div>
                        }
                        {true === loadingCategories &&
                            <div style={{marginBottom: '80px', marginTop: '60px', marginLeft: '15px'}}>
                                <CategoryFilterLoader/>
                            </div>
                        }
                        {(true !== loadingCategories && categories.length > 0) &&
                            <div className="category-container">
                                <Slider {...CategorySettings} className="category-slider">
                                    {categories.map((item)=> (
                                        <CategoryCard
                                            key={item.id.toString()}
                                            title={item?.name?.[props.APP_LOCALE]}
                                            image={item.icon.thumb}
                                            onClick={() => didPressCategoryItem(item)}
                                        />
                                    ))}
                                </Slider>
                                <div className="category-prev-arrow-bg"></div>
                                <div className="category-next-arrow-bg"></div>
                            </div>
                        }
                    </div>
                    <div className="arrows-top arrows-container main-search-expert-container">
                        {(true !== loadingExperts && experts.length > 0) &&
                            <div className="category-header-row" style={{marginTop: '20px', marginBottom: '30px'}}>
                                <div>
                                    <h3>{t('search:experts')}</h3>
                                </div>
                                <div>
                                    <button className="grey-btn" onClick={didPressViewAllExperts}>
                                        <span className="btn-text">{t('search:view_all')}</span>
                                        <img alt={t('search:view_all')} className="chevron-bottom" src={ICON_CHEVRON_BOTTOM.default}/>
                                    </button>
                                </div>
                            </div>
                        }
                        {true === loadingExperts &&
                            <div style={{marginBottom: '80px'}}>
                                <FeaturedExpertLoader/>
                            </div>
                        }
                        {(true !== loadingExperts && experts.length > 0) &&
                            <div>
                                <Slider {...ExpertSettings} className="main-search-expert-slider">
                                    {experts.map((item, index) => (
                                        <div key={index.toString()} className="expert-card-parent">
                                            <ExpertCard slug={item.slug} image={item.profile_picture.base_url+item.profile_picture.files['300X300']} name={item?.name?.[props.APP_LOCALE]} expertise={item?.categories?.[props?.APP_LOCALE]}/>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        }
                    </div>
                    <div className="arrows-top arrows-container search-session-container">
                        {(true !== loadingSessions && sessions.length > 0) &&
                            <div className="category-header-row" style={{marginTop: '30px', marginBottom: '30px'}}>
                                <div>
                                    <h3>{t('search:sessions')}</h3>
                                </div>
                                <div>
                                    <button className="grey-btn" onClick={didPressViewAllSessions}>
                                        <span className="btn-text">{t('search:view_all')}</span>
                                        <img alt={t('search:view_all')} className="chevron-bottom" src={ICON_CHEVRON_BOTTOM.default}/>
                                    </button>
                                </div>
                            </div>
                        }
                        {true === loadingSessions &&
                            <FeaturedSessionLoader/>
                        }
                        {(true !== loadingSessions && sessions.length > 0) &&
                            <Slider {...SessionSettings} className="session-slider">
                                {sessions.map((item)=> (
                                    <Col key={item.id.toString()} as={Link} to={'/session/'+item.slug} className="session-card-wrapper">
                                        <SessionCard image={item.cover_picture.base_url+item?.cover_picture?.files?.['700X400']} title={item?.title?.[props.APP_LOCALE]}/>
                                    </Col>
                                ))}
                            </Slider>   
                        }
                    </div>
                    {(true !== loadingCategories && categories.length === 0) && (true !== loadingExperts && experts.length === 0) && (true !== loadingSessions && sessions.length === 0) &&
                        <SearchNotFound/>
                    }
                </Container>
            </Container>
        </div>
    );
}


export function NextArrow(props) {
    const { style, onClick } = props;
    return (
        <div className="slick-arrow-next" style={{ ...style, display: "flex"}} onClick={onClick}>
            <FontAwesomeIcon className="carousal-icon" icon={faChevronRight}/>
        </div>
    );
}

export function PrevArrow(props) {
    const { style, onClick } = props;
    return (
        <div className="slick-arrow-prev" style={{ ...style, display: "flex"}} onClick={onClick}>
            <FontAwesomeIcon className="carousal-icon" icon={faChevronLeft}/>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        APP_LOCALE: state.app.app_locale,
        IS_RTL: state.app.is_rtl,
        THEME: state.app.theme,
        DEVICE_COUNTRY_CODE: state.app?.device_country?.country_code || 'kw'
    }
}

export default connect(mapStateToProps, null)(SearchMain);