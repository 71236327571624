import React from 'react'
import './VideoCard.scss'
import {ICON_PLAY_VIDE} from './../../assets/images/Index'

export default function VideoCard(props) {
    const height = props.height;
    return (
        <div className="video-card-container" style={{borderWidth: props.borderColor && '1px', borderStyle: props.borderColor && 'solid', borderColor: props.borderColor,}}>
            <div className="card-img-wrapper">
                <img src={props.image} style={{height}} alt={props.title}/>
                <div className="hover-overlay" style={{display: props.big && 'none'}}></div>
            </div>
            <div className="video-card-overlay">
                <div className="video-card-content-container">
                    <h6>{props.title}</h6>
                    <img src={ICON_PLAY_VIDE.default} style={{width: props.height && '20px'}} alt={'video-icon'}/>
                </div>
            </div>
        </div>
    )
}
