import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import commonEN from "./assets/locales/en/common.json";
import commonAR from "./assets/locales/ar/common.json";
import sessionEN from "./assets/locales/en/session.json";
import sessionAR from "./assets/locales/ar/session.json";
import homeEN from "./assets/locales/en/home.json";
import homeAR from "./assets/locales/ar/home.json";
import searchEN from "./assets/locales/en/search.json";
import searchAR from "./assets/locales/ar/search.json";
import blogEN from "./assets/locales/en/blog.json";
import blogAR from "./assets/locales/ar/blog.json";
import forExpertEN from "./assets/locales/en/for-expert.json";
import forExpertAR from "./assets/locales/ar/for-expert.json";
import contactEN from "./assets/locales/en/contact.json";
import contactAR from "./assets/locales/ar/contact.json";

const resources = {
    en: {
        common: commonEN,
        home: homeEN,
        session: sessionEN,
        search: searchEN,
        blog: blogEN,
        for_expert: forExpertEN,
        contact: contactEN
    },
    ar: {
        common: commonAR,
        home: homeAR,
        session: sessionAR,
        search: searchAR,
        blog: blogAR,
        for_expert: forExpertAR,
        contact: contactAR
    }
};

i18n.use(initReactI18next).use(LanguageDetector).init({
    resources,
    fallbackLng: 'en',
    whitelist: ['en', 'ar'],
    ns: ["common", "home"],
    defaultNS: "common",
    interpolation: {
        escapeValue: false
    }
}).then(function(t) {
    document.body.id = 'app-locale-'+i18n.language;
    document.body.classList.add('app-locale');
});

export default i18n;