import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import './ContentLoader.scss';
import './ContentLoaderResponsive.scss';

const LOADING_BG_COLOR = (theme) => {
    return 'dark' === theme ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)';
};

const LOWER_LAYER = '#1f1f1f';
const LOWER_LAYER_LIGHT = '#e0e0e0';
const UPPER_LAYER = '#2a2a2a';
const UPPER_LAYER_LIGHT = '#d4d4d4';

// return 'dark' === theme ? '#1f1f1f' : '#e0e0e0';

const ContentLoader = (props) => {
    const width = props?.width || '100%';
    const height = props?.height || '200px';
    const topLeftRadius = props?.topLeftRadius || '0px';
    const topRightRadius = props?.topRightRadius || '0px';
    const bottomLeftRadius = props?.bottomLeftRadius || '0px';
    const bottomRightRadius = props?.bottomRightRadius || '0px';
    const borderRadius = props?.borderRadius || '0px';
    const hasBorderRadius = props?.borderRadius || false;
    const backgroundColor = props?.backgroundColor || LOADING_BG_COLOR(props?.theme);
    const style = {
        width: width,
        height: height,
        backgroundColor: backgroundColor,
        borderTopLeftRadius: topLeftRadius,
        borderTopRightRadius: topRightRadius,
        borderBottomLeftRadius: bottomLeftRadius,
        borderBottomRightRadius: bottomRightRadius
    };
    if (hasBorderRadius) {
        style.borderRadius = borderRadius;
    }
    return(
        <div className={`animate-flicker ${props.className}`} style={style}>
            {props.children}
        </div>
    );
}

function HomeBanner(props) {
    const UPPER_COLOR = props.THEME === 'dark' ? UPPER_LAYER : UPPER_LAYER_LIGHT;
    return(
        <ContentLoader height={'auto'} backgroundColor="transparent" className="home-banner-placeholder">
            <div className="home-banner-loader-container">
                {Array(2).fill().map((_,index)=> (
                    <div className="home-banner-loader-slide" key={index.toString()} style={{backgroundColor: UPPER_COLOR}}/>
                ))}
            </div>
        </ContentLoader>
    )
};
// height: 215px
function FeaturedExpert(props) {
    const UPPER_COLOR = props.THEME === 'dark' ? UPPER_LAYER : UPPER_LAYER_LIGHT;
    return (
        <ContentLoader height={'auto'} backgroundColor="transparent">
            <Col>
                <div style={{width: '100%', display: 'inline-flex', overflow: 'hidden',}}>
                    {Array(8).fill().map((_,index)=> (
                        <div key={index.toString()} style={{marginRight: '15px'}}>
                            <div style={{minWidth: '165px', backgroundColor: UPPER_COLOR, height: '215px', borderRadius: '15px', marginBottom: '20px'}}/>
                            <div style={{ height: '15px', width: '70%', marginLeft: 'auto', marginRight: 'auto', backgroundColor:UPPER_COLOR, marginBottom: '5px'}}/>
                            <div style={{ height: '15px', width: '50%', marginLeft: 'auto', marginRight: 'auto', backgroundColor:UPPER_COLOR}}/>
                            <div style={{ height: '31px', width: '50%', marginLeft: 'auto', marginRight: 'auto',}}/>
                        </div>
                    ))}
                </div>
            </Col>
        </ContentLoader>
    );
}

function CategoryFilter(props) {
    const UPPER_COLOR = props.THEME === 'dark' ? UPPER_LAYER : UPPER_LAYER_LIGHT;
    return(
        <ContentLoader height={'auto'} backgroundColor="transparent">
            <Col className="category-filter-placeholder">
                <div style={{width: '100%', display: 'inline-flex', overflow: 'hidden',}}>
                    {Array(10).fill().map((_,index)=> (
                        <div key={index.toString()} style={{marginLeft: '10px', marginRight: '10px',}}>
                            <div style={{minWidth: '112px', backgroundColor: UPPER_COLOR, height: '68px', borderRadius: '8px', marginTop: '22px', marginBottom: '12px'}}/>
                            <div style={{ height: '15px', width: '60%', marginLeft: 'auto', marginRight: 'auto', backgroundColor:UPPER_COLOR}}/>
                            <div style={{ height: '28px', width: '60%', marginLeft: 'auto', marginRight: 'auto' }}/>
                        </div>
                    ))}
                </div>
            </Col>
        </ContentLoader>
    );
}

function VideoChannelFilter(props) {
    const UPPER_COLOR = props.THEME === 'dark' ? UPPER_LAYER : UPPER_LAYER_LIGHT;
    return(
        <ContentLoader height={'auto'} backgroundColor="transparent">
            <div style={{flexDirection: 'row', display: 'flex'}}>
                {Array(10).fill().map((_, index) => {
                    return(
                        <div key={index.toString()} className="video-channel-filter-tab-loader" style={{backgroundColor: UPPER_COLOR}}/>
                    );
                })}
            </div>
        </ContentLoader>
    );
}

function FeaturedSession(props) {
    const UPPER_COLOR = props.THEME === 'dark' ? UPPER_LAYER : UPPER_LAYER_LIGHT;
    return(
        <ContentLoader height={'auto'} backgroundColor="transparent">
            <div className="featured-session-loader">
                {Array(3).fill().map((_, index) => {
                    return(
                        <div className="session-loader-card" key={index.toString()}>
                            {/* <ContentLoader width="100%" height="100%" borderRadius="12px" theme={props?.THEME} {...props}/> */}
                            <div style={{width: '100%', height: '100%', borderRadius: '12px', backgroundColor: UPPER_COLOR}}/>
                        </div>
                    );
                })}
            </div>
            <div className="featured-session-loader" style={{marginTop: '20px', marginBottom: '24px'}}>
                {Array(3).fill().map((_, index) => {
                    return(
                        <div className="session-loader-card" key={index.toString()}>
                            <div style={{width: '100%', height: '100%', borderRadius: '12px', backgroundColor: UPPER_COLOR}}/>
                        </div>
                    );
                })}
            </div>
            {/* <div style={{height: '24px', backgroundColor: 'red'}}></div> */}
        </ContentLoader>
    );
}

function FeaturedVideo(props) {
    const UPPER_COLOR = props.THEME === 'dark' ? UPPER_LAYER : UPPER_LAYER_LIGHT;
    return(
        <ContentLoader height={'auto'} backgroundColor="transparent">
            <div style={{flexDirection: 'row', display: 'flex'}}>
                {Array(3).fill().map((_, index) => {
                    return(
                        <div key={index.toString()} style={{flex: 1, height: '220px', margin: '0px 10px', borderRadius: '10px', overflow: 'hidden'}}>
                            <div style={{height: '100%', width: '100%', backgroundColor: UPPER_COLOR}}/>
                        </div>
                    );
                })}
            </div>
            <div style={{flexDirection: 'row', display: 'flex', marginTop: '20px', marginBottom: '44px'}}>
                {Array(3).fill().map((_, index) => {
                    return(
                        <div key={index.toString()} style={{flex: 1, height: '220px', margin: '0px 10px', borderRadius: '10px', overflow: 'hidden'}}>
                            <div style={{height: '100%', width: '100%', backgroundColor: UPPER_COLOR}}/>
                        </div>
                    );
                })}
            </div>
        </ContentLoader>
    );
}

function FeaturedArticle(props) {
    const UPPER_COLOR = props.THEME === 'dark' ? UPPER_LAYER : UPPER_LAYER_LIGHT;
    const LOWER_COLOR = props.THEME === 'dark' ? LOWER_LAYER : LOWER_LAYER_LIGHT;
    return(
        <ContentLoader height={'auto'} backgroundColor="transparent">
            <Col className="featured-article-loader-container">
                <div className="featured-article-loader">
                    {Array(3).fill().map((_,index)=> (
                        <Col xs={12} className="article-loader-card" key={index.toString()} style={{backgroundColor: LOWER_COLOR}}>
                            {/* <div style={{height: '270px', backgroundColor: UPPER_COLOR}}/> */}
                        </Col>
                    ))}
                </div>
            </Col>
        </ContentLoader>
    );
}

function Testimonial(props) {
    const UPPER_COLOR = props.THEME === 'dark' ? UPPER_LAYER : UPPER_LAYER_LIGHT;
    return(
        <ContentLoader height={'auto'} backgroundColor="transparent">
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <div style={{width: '80px', height: '80px', borderRadius: '40px', backgroundColor: UPPER_COLOR}}/>
                <div style={{marginTop: "40px", display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <div style={{width: '500px', height: '15px', borderRadius: '40px', backgroundColor: UPPER_COLOR, marginBottom: '5px'}}/>
                    <div style={{width: '300px', height: '15px', borderRadius: '40px', backgroundColor: UPPER_COLOR}}/>
                </div>
                <div style={{marginTop: "41px"}}>
                    {/* <ContentLoader width="135px" height="10px" borderRadius="4px" backgroundColor={'rgba(0, 0, 0, 0.2)'} theme={props?.THEME} {...props}/> */}
                    <div style={{width: '135px', height: '10px', borderRadius: '4px', backgroundColor: UPPER_COLOR}}/>
                </div>
            </div>
        </ContentLoader>
    )
}

function Faq(props) {
    const LOWER_COLOR = props.THEME === 'dark' ? LOWER_LAYER : LOWER_LAYER_LIGHT;
    return (
        <ContentLoader height="auto" backgroundColor="transparent" {...props}>
            {Array(2).fill().map((_,index)=> (
                <Row key={index.toString()} style={{marginBottom: '105px'}}>
                    <Col lg={4}>
                        <div style={{width: '70%', height: '5px', backgroundColor: LOWER_COLOR, marginBottom: '25px'}}/>
                        <div style={{width: '50%', height: '30px', backgroundColor: LOWER_COLOR}}/>
                    </Col>
                    <Col lg={8}>
                        {Array(4).fill().map((_,index)=> (
                            <div key={index.toString()} style={{width: '100%', height: '70px', backgroundColor: LOWER_COLOR, borderBottom: props.THEME === 'light' ? '2px solid #fff' : '2px solid rgba(255,255,255,0.1)'}}/>
                        ))}
                    </Col>
                </Row>
            ))}
        </ContentLoader>
    )
}

function ExpertDetails(props) {
    const UPPER_COLOR = props.THEME === 'dark' ? UPPER_LAYER : UPPER_LAYER_LIGHT;
    const LOWER_COLOR = props.THEME === 'dark' ? LOWER_LAYER : LOWER_LAYER_LIGHT;

    const SPACING_RIGHT = props.IS_RTL ? '7.5px' : '7.5px';
    const SPACING_LEFT = props.IS_RTL ? '7.5px' : '7.5px';
    return(
        <ContentLoader height={'auto'}>            
            <div style={{height: '330px', backgroundColor: LOWER_COLOR}}/>
            <Container style={{marginTop: '-150px'}}>
                <Row>
                    <Col lg={3} style={{paddingRight: '10px'}}>
                        <div style={{height: '260px', backgroundColor: UPPER_COLOR, borderRadius: '15px', marginBottom: '15px'}}/>
                        <div style={{display: 'flex'}}>
                            <div style={{height: '38px', flex: 1, backgroundColor: UPPER_COLOR, marginRight: SPACING_RIGHT, marginLeft: SPACING_LEFT, borderRadius: '5px'}}/>
                            <div style={{height: '38px', flex: 1, backgroundColor: UPPER_COLOR, marginLeft: SPACING_LEFT, marginRight: SPACING_RIGHT, borderRadius: '5px'}}/>
                        </div>
                        <div style={{height: '105px', backgroundColor: UPPER_COLOR, marginTop: '15px', borderRadius: '10px', marginBottom: '15px'}}/>
                        <div style={{display: 'flex'}}>
                            {Array(4).fill().map((_, index)=> (
                                <div key={index.toString()} style={{height: '40px', width: '40px', backgroundColor: UPPER_COLOR, borderRadius: '20px', marginRight: '12px'}}/>
                            ))}
                        </div>
                    </Col>
                    <Col lg={9} style={{paddingLeft: '10px', marginBottom: '250px'}}>
                        <div style={{height: '800px', backgroundColor: UPPER_COLOR, borderRadius: '15px'}}></div>
                    </Col>
                </Row>
            </Container>
        </ContentLoader>
    )
}

function Session(props) {
    const UPPER_COLOR = props.THEME === 'dark' ? UPPER_LAYER : UPPER_LAYER_LIGHT;
    const LOWER_COLOR = props.THEME === 'dark' ? LOWER_LAYER : LOWER_LAYER_LIGHT;

    const SPACING_RIGHT = props.IS_RTL ? '7.5px' : '7.5px';
    const SPACING_LEFT = props.IS_RTL ? '7.5px' : '7.5px';
    return(
        <ContentLoader height={'auto'}>            
            <div style={{height: '330px', backgroundColor: LOWER_COLOR}}/>
            <Container style={{marginTop: '-150px'}}>
                <Row>
                    <Col lg={3} style={{paddingRight: '10px'}}>
                        <div style={{height: '156px', backgroundColor: UPPER_COLOR, borderRadius: '10px', marginBottom: '15px'}}/>
                        <div style={{display: 'flex'}}>
                            <div style={{height: '38px', flex: 1, backgroundColor: UPPER_COLOR, marginRight: SPACING_RIGHT, marginLeft: SPACING_LEFT, borderRadius: '5px'}}/>
                            <div style={{height: '38px', flex: 1, backgroundColor: UPPER_COLOR, marginLeft: SPACING_LEFT, marginRight: SPACING_RIGHT, borderRadius: '5px'}}/>
                        </div>
                        <div style={{height: '200px', backgroundColor: UPPER_COLOR, marginTop: '15px', borderRadius: '10px', marginBottom: '15px'}}/>
                        <div style={{display: 'flex'}}>
                            {Array(4).fill().map((_, index)=> (
                                <div key={index.toString()} style={{height: '40px', width: '40px', backgroundColor: UPPER_COLOR, borderRadius: '20px', marginRight: '12px'}}/>
                            ))}
                        </div>
                    </Col>
                    <Col lg={9} style={{paddingLeft: '10px', marginBottom: '250px'}}>
                        <div style={{height: '800px', backgroundColor: UPPER_COLOR, borderRadius: '15px'}}></div>
                    </Col>
                </Row>
            </Container>
        </ContentLoader>
    )
}

function Articles(props) {
    const LOWER_COLOR = props.THEME === 'dark' ? LOWER_LAYER : LOWER_LAYER_LIGHT;
    return(
        <ContentLoader height={'auto'} backgroundColor="transparent">
            <Col lg={12}>
                <div style={{height: '400px', backgroundColor: LOWER_COLOR, borderRadius: '10px', marginBottom: '30px'}}/>
            </Col>
            <Col>
                <Row>
                    <Col lg={5}>
                        <div style={{height: '460px', backgroundColor: LOWER_COLOR, borderRadius: '10px'}}/>
                    </Col>
                    <Col lg={7}>
                        <div style={{height: '460px', backgroundColor: LOWER_COLOR, borderRadius: '10px'}}/>
                    </Col>
                </Row>
            </Col>
        </ContentLoader>
    )
}

function ArticleDetials(props) {
    const UPPER_COLOR = props.THEME === 'dark' ? UPPER_LAYER : UPPER_LAYER_LIGHT;
    return(
        <ContentLoader height={'auto'}>
            <Container style={{paddingBottom: '250px', paddingTop: '75px'}}>
                <div style={{maxWidth: '740px', marginLeft: 'auto', marginRight: 'auto'}}>
                    <div style={{height: '20px', width: '15%', backgroundColor: UPPER_COLOR, borderRadius: '5px', marginBottom: '10px'}}/>
                    <div style={{height: '20px', width: '15%', backgroundColor: UPPER_COLOR, borderRadius: '5px', marginBottom: '30px'}}/>
                    <div style={{height: '32px', width: '80%', backgroundColor: UPPER_COLOR, borderRadius: '5px', marginBottom: '10px'}}/>
                    <div style={{height: '32px', width: '70%', backgroundColor: UPPER_COLOR, borderRadius: '5px', marginBottom: '10px'}}/>
                    <div style={{height: '32px', width: '60%', backgroundColor: UPPER_COLOR, borderRadius: '5px', marginBottom: '30px'}}/>
                    <div style={{display: 'flex', marginBottom: '30px'}}>
                        {Array(4).fill().map((_,index)=> (
                            <div key={index.toString()} style={{height: '40px', width: '40px', borderRadius: '8px', backgroundColor: UPPER_COLOR, marginRight: '15px'}}/>
                            ))}
                    </div>
                    <div style={{width: '100%', height: '500px', backgroundColor: UPPER_COLOR, marginBottom: '30px'}}/>
                    <div style={{height: '32px', width: '80%', backgroundColor: UPPER_COLOR, marginBottom: '10px'}}/>
                    <div style={{height: '32px', width: '70%', backgroundColor: UPPER_COLOR, marginBottom: '10px'}}/>
                    <div style={{height: '32px', width: '60%', backgroundColor: UPPER_COLOR, marginBottom: '10px'}}/>
                </div>
            </Container>
        </ContentLoader>
    )
}

function SearchExpert(props) {
    const UPPER_COLOR = props.THEME === 'dark' ? UPPER_LAYER : UPPER_LAYER_LIGHT;
    return(
        <ContentLoader height={'auto'} backgroundColor="transparent">
            <div style={{width: '100%', height: '50px', marginBottom: '40px'}}></div>
            <Row>
                {Array(18).fill().map((_,index)=> (
                    <Col key={index.toString()} md={3} lg={3} xl={2} xs={6} >
                        <div style={{width: '100%', height: '214px', backgroundColor: UPPER_COLOR, marginBottom: '25px', borderRadius: '15px'}}></div>
                    </Col>
                ))}
            </Row>
        </ContentLoader>
    )
}

function SearchCategory(props) {
    const UPPER_COLOR = props.THEME === 'dark' ? UPPER_LAYER : UPPER_LAYER_LIGHT;
    return(
        <ContentLoader height={'auto'} backgroundColor="transparent">
            {/* <Row className="justify-content-center">
                {categories.map((item)=> (
                    <Col md={'auto'} xs={4} className="category-card-col-container">

                    </Col>
                ))}
            </Row> */}
            <Row className="justify-content-center">
                {Array(28).fill().map((_,index)=> (
                    <Col key={index.toString()} md={'auto'} xs={4}>
                        <div style={{ width: '112px', height: '70px', backgroundColor: UPPER_COLOR, margin: '10px', borderRadius: '8px'}}></div>
                        <div style={{ height: '27px'}}></div>
                    </Col>
                ))}
            </Row>
        </ContentLoader>
    )
}

function SearchSession(props) {
    const UPPER_COLOR = props.THEME === 'dark' ? UPPER_LAYER : UPPER_LAYER_LIGHT;
    return(
        <ContentLoader height={'auto'} backgroundColor="transparent">
            <div style={{width: '100%', height: '50px', marginBottom: '40px'}}></div>
            <Row>
                {Array(9).fill().map((_,index)=> (
                    <Col key={index.toString()} md={4} xs={12}>
                        <div style={{width: '100%', height: '220px', backgroundColor: UPPER_COLOR, marginBottom: '25px', borderRadius: '15px'}}></div>
                    </Col>
                ))}
            </Row>
        </ContentLoader>
    )
}

function SearchCategoryDetail(props) {
    const UPPER_COLOR = props.THEME === 'dark' ? UPPER_LAYER : UPPER_LAYER_LIGHT;
    return(
        <ContentLoader height={'auto'} backgroundColor="transparent">
            <div style={{paddingBottom: '250px'}}>
                <div style={{width: '280px', height: '160px', backgroundColor: UPPER_COLOR, borderRadius: '10px', marginLeft: 'auto', marginRight: 'auto', marginTop: '-110px', marginBottom: '20px'}}></div>
                <div style={{width: '200px', height: '30px', backgroundColor: UPPER_COLOR, marginLeft: 'auto', marginRight: 'auto', marginBottom: '65px' }}></div>
                <Container>
                    <Row>
                        {Array(18).fill().map((_,index)=> (
                            <Col key={index.toString()} md={3} lg={3} xl={2} xs={6} >
                                <div style={{width: '100%', height: '214px', backgroundColor: UPPER_COLOR, marginBottom: '25px', borderRadius: '15px'}}></div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        </ContentLoader>
    )
}

function PrivacyPolicy(props) {
    const LOWER_COLOR = props.THEME === 'dark' ? LOWER_LAYER : LOWER_LAYER_LIGHT;
    return(
        <ContentLoader height={'auto'} backgroundColor="transparent">
            <div style={{paddingBottom: '250px', paddingTop: '60px'}}>
                {/* <div style={{height: '220px', backgroundColor: LOWER_COLOR, marginBottom: '60px'}}></div> */}
                <Container>
                    {Array(3).fill().map((_,index)=> (
                        <div key={index.toString()} style={{marginBottom: '60px'}}>
                            <div style={{height: '30px', width: '30%', backgroundColor: LOWER_COLOR,marginBottom: '30px'}}></div>
                            <div style={{height: '20px', width: '100%', backgroundColor: LOWER_COLOR,marginBottom: '10px'}}></div>
                            <div style={{height: '20px', width: '100%', backgroundColor: LOWER_COLOR,marginBottom: '10px'}}></div>
                            <div style={{height: '20px', width: '100%', backgroundColor: LOWER_COLOR,marginBottom: '10px'}}></div>
                            <div style={{height: '20px', width: '100%', backgroundColor: LOWER_COLOR,marginBottom: '10px'}}></div>
                            <div style={{height: '20px', width: '100%', backgroundColor: LOWER_COLOR,marginBottom: '10px'}}></div>
                            {/* <div style={{height: '20px', width: '5%', backgroundColor: LOWER_COLOR,marginBottom: '10px'}}></div> */}
                        </div>
                    ))}
                </Container>
            </div>
        </ContentLoader>
    )
}



const mapStateToProps = state => {
    return {
        APP_LOCALE: state.app.app_locale,
        IS_RTL: state.app.is_rtl,
        THEME: state.app.theme
    }
}

export const HomeBannerLoader = connect(mapStateToProps, null)(HomeBanner);
export const FeaturedExpertLoader = connect(mapStateToProps, null)(FeaturedExpert);
export const FeaturedSessionLoader = connect(mapStateToProps, null)(FeaturedSession);
export const FeaturedArticleLoader = connect(mapStateToProps, null)(FeaturedArticle);
export const CategoryFilterLoader = connect(mapStateToProps, null)(CategoryFilter);
export const VideoChannelFilterLoader = connect(mapStateToProps, null)(VideoChannelFilter);
export const FeaturedVideoLoader = connect(mapStateToProps, null)(FeaturedVideo);
export const TestimonialLoader = connect(mapStateToProps, null)(Testimonial);
export const FaqLoader = connect(mapStateToProps, null)(Faq);
export const ExpertDetailLoader = connect(mapStateToProps, null)(ExpertDetails);
export const SessionDetailLoader = connect(mapStateToProps, null)(Session);
export const ArticlesLoader = connect(mapStateToProps, null)(Articles);
export const ArticleDetailsLoader = connect(mapStateToProps, null)(ArticleDetials);
export const SearchExpertLoader = connect(mapStateToProps, null)(SearchExpert);
export const SearchCategoryLoader = connect(mapStateToProps, null)(SearchCategory);
export const SearchSessionsLoader = connect(mapStateToProps, null)(SearchSession);
export const SearchCategoryDetailLoader = connect(mapStateToProps, null)(SearchCategoryDetail);
export const PrivacyLoader = connect(mapStateToProps, null)(PrivacyPolicy);
