import React from 'react'
import Wave from 'react-wavify';
import './wave-container.scss'

export default function WaveContainer(props) {
	return (
		<div className="wave-container-absolute" style={{top: props.top ? props.top : '-130px' }}>
			<div className="wave-container">
				<Wave className="custom-wave-under" fill={props.fill + '0.5)'}
					paused={props.paused}
					options={{
						height: 20,
						amplitude: 30,
						speed: 0.1,
						points: 2,
					}}
				/>
				<Wave className="custom-wave-middle" fill={props.fill + '0.7)'}
					paused={props.paused}
					options={{
						height: 20,
						amplitude: 35,
						speed: 0.12,
						points: 2,
					}}
				/>
				<Wave className="custom-wave-over" fill={props.fill + '1)'}
					paused={props.paused}
					options={{
						height: 20,
						amplitude: 25,
						speed: 0.15,
						points: 2,
					}}
				/>
			</div>
		</div>
	)
}
