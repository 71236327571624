import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootReducer from '../reducers';

const persistConfig = {
    key: 'root',
    storage: storage,
    blacklist: [],
    stateReconciler: autoMergeLevel2
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export default () => {
    const middlewares = [logger, thunk];
    const store = createStore(persistedReducer, applyMiddleware(...middlewares));
    const persistor = persistStore(store);
    return { store, persistor };
}