import './navigation-bar.scss';
import './NavigationBarRresponsive.scss';
import React, { useState, useEffect } from "react";
import { Navbar, Container, Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import { Link } from 'react-router-dom';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { faBars, faSearch } from '@fortawesome/free-solid-svg-icons';
import Nav from 'react-bootstrap/Nav';
import BeatLoader from "react-spinners/BeatLoader";
import { LOGO, LOGO_LIGHT } from './../../assets/images/Index'
import { THEME_COLORS } from './../../utils/Constant';
import FontIcon from '../FontIcon/FontIcon';
import EventEmitter from './../../service/EventEmitter';
import { setLocale, setTheme, setDeviceCountry } from './../../redux/actions/app';
import { ping, getRegionList } from './../../service/Api/ApiRequest';

let REGION_ITEMS = [];
const _event = EventEmitter.getInstance();

function NavigationBar(props) {
    const { t, i18n } = useTranslation(['common']);
    const [langauge, setLangauge] = React.useState(i18n.language);
    const [regions, setRegions] = useState([]);
    const [isDarkMode, setDarkMode] = useState('dark' === props.THEME);
    const [displayRegionLoader, setDisplayRegionLoader] = useState(false);
    const onRegionToggle = (isOpen) => {
        if (true === isOpen) {
            setDisplayRegionLoader(true);
            getRegionList().then((response) => {
                const items = response?.data?.data || [];
                REGION_ITEMS = items;
                setRegions(items);
                setDisplayRegionLoader(false);
            });
        } else {
            setRegions([]);
            setDisplayRegionLoader(false);
        }
    };
    const toggleDarkMode = (checked) => {
        const theme = true === checked ? 'dark' : 'light';
        document.body.classList.remove('app-theme-'+props.THEME);
        document.body.classList.add('app-theme-'+theme);
        props.setTheme(theme);
        setDarkMode(checked);
    };
    const handleLanguageChange = (event) => {
        const locale = event.target.value;
        document.body.id = 'app-locale-'+locale;
        i18n.changeLanguage(locale);
        props.setLocale(locale);
        setLangauge(locale);
    };
    const onRegionSearch = (event) => {
        const text = event.target.value;
        setRegions(REGION_ITEMS.filter(data => data?.name?.[props.APP_LOCALE].toLowerCase().includes(text.toLowerCase())));
    }
    const didSelectRegion = (region) => {
        const DEVICE_COUNTRY = {};
        DEVICE_COUNTRY.ip = '';
        DEVICE_COUNTRY.country_code = region.alpha2_code;
        DEVICE_COUNTRY.country_flag = region.flag.thumb;
        DEVICE_COUNTRY.country_name = region.name;
        props.setDeviceCountry(DEVICE_COUNTRY);
        _event.emit('app.region_change', {data: DEVICE_COUNTRY, action: 'refresh_items'});
    }
    useEffect(() => {
        if (null === props.DEVICE_COUNTRY) {
            ping().then((response) => {
                const DEVICE_COUNTRY = response?.data.ip_trace || null;
                props.setDeviceCountry(DEVICE_COUNTRY);
                _event.emit('app.region_change', {data: DEVICE_COUNTRY, action: 'refresh_items'});
            });
        }
    }, []);
    const WIDTH = window.innerWidth;
    return (
        <>
        <div className="qwerty"></div>
            <Container fluid className="nav-container">
                <Container>
                    <Navbar className="custom-navbar" expand="lg" variant="dark">
                        <Navbar.Brand as={Link} to="/">
                            <img className="logo" src={LOGO.default} alt={'logo'}/>
                            <img className="logo-light" src={LOGO_LIGHT.default} alt={'logo-light'}/>
                        </Navbar.Brand>
                        {WIDTH < 575.98 &&<Dropdown className="country-dropdown responsive-country-dropdown" onToggle={(isOpen) => onRegionToggle(isOpen)}>
                            <Dropdown.Toggle className="nav-btn">
                                {null === props.DEVICE_COUNTRY ?
                                    <BeatLoader color={THEME_COLORS?.[props.THEME]?.color?.COUNTRY_NAV_LOADING_COLOR} loading={true} size={8} />
                                :
                                    <img className="currency-btn" src={props.DEVICE_COUNTRY.country_flag} alt={props.DEVICE_COUNTRY.country_flag}/>
                                }
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <div className="search-input-container">
                                    <input placeholder={t('common:main_navigation.search_your_country')} onChange={onRegionSearch}/>
                                    <div className="search-icon-container">
                                        <FontIcon icon={faSearch}/>
                                    </div>
                                </div>
                                {true === displayRegionLoader ?
                                    <div style={{ flex: 1, alignItems: 'center', justifyContent: 'center', height: '300px', display: 'flex'}}>
                                        <BeatLoader color={THEME_COLORS?.[props.THEME]?.color?.COUNTRY_NAV_LOADING_COLOR} loading={true} size={8} />
                                    </div>
                                :
                                    regions.map((region)=> {
                                        return(
                                            <Dropdown.Item href="#/action-1" onSelect={() => didSelectRegion(region)}>
                                                <>
                                                    <img className="currency-btn" src={region.flag.thumb} alt={region.flag.thumb}/>
                                                    {region.name[props.APP_LOCALE]}
                                                </>
                                            </Dropdown.Item>
                                        )
                                    })
                                }
                            </Dropdown.Menu>
                        </Dropdown>}
                        {WIDTH < 575.98 &&<Nav.Link className="nav-btn dark-mode-switcher">
                            <DarkModeSwitch
                                checked={isDarkMode}
                                onChange={toggleDarkMode}
                                size={WIDTH < 575.98 ? 20 : 25}
                            />
                        </Nav.Link>}
                        <Navbar.Toggle aria-controls="basic-navbar-nav">
                            <FontIcon icon={faBars}/>
                        </Navbar.Toggle>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="justify-content-center">
                                <Nav.Link className="active" as={Link} to="/">{t('common:main_navigation.home')}</Nav.Link>
                                <Nav.Link as={Link} to="/search">{t('common:main_navigation.search')}</Nav.Link>
                                <Nav.Link as={Link} to="/blog">{t('common:main_navigation.blog')}</Nav.Link>
                                {/* <Nav.Link as={Link} to="/for-experts">{t('common:main_navigation.for_experts')}</Nav.Link> */}
                                <Nav.Link as={Link} to="/contact">{t('common:main_navigation.contact')}</Nav.Link>
                            </Nav>
                            <FormControl>
                                <Select labelId="language-label" id="app-language-select" value={langauge} onChange={handleLanguageChange} inputProps={{'aria-label': 'Without label'}}>
                                    <MenuItem value={'ar'}>
                                        <div className="ar-font">
                                            العربي
                                        </div>
                                    </MenuItem>
                                    <MenuItem value={'en'}>English</MenuItem>
                                </Select>
                            </FormControl>
                            {WIDTH > 575.98 &&<Dropdown className="country-dropdown" onToggle={(isOpen) => onRegionToggle(isOpen)}>
                                <Dropdown.Toggle className="nav-btn">
                                    {null === props.DEVICE_COUNTRY ?
                                        <BeatLoader color={THEME_COLORS?.[props.THEME]?.color?.COUNTRY_NAV_LOADING_COLOR} loading={true} size={8} />
                                    :
                                        <img alt="device country" className="currency-btn" src={props.DEVICE_COUNTRY.country_flag} alt={'flag'}/>
                                    }
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <div className="search-input-container">
                                        <input placeholder={t('common:main_navigation.search_your_country')} onChange={onRegionSearch}/>
                                        <div className="search-icon-container">
                                            <FontIcon icon={faSearch}/>
                                        </div>
                                    </div>
                                    {true === displayRegionLoader ?
                                        <div style={{alignItems: 'center', flex: 1, justifyContent: 'center', height: '300px', display: 'flex'}}>
                                            <BeatLoader color={THEME_COLORS?.[props.THEME]?.color?.COUNTRY_NAV_LOADING_COLOR} loading={true} size={8} />
                                        </div>
                                    :
                                        regions.map((region)=> {
                                            return(
                                                <Dropdown.Item key={region.name[props.APP_LOCALE]} href="#" onSelect={() => didSelectRegion(region)}>
                                                    <>
                                                        <img alt={region.name[props.APP_LOCALE]} className="currency-btn" src={region.flag.thumb} alt={'region'}/>
                                                        {region.name[props.APP_LOCALE]}
                                                    </>
                                                </Dropdown.Item>
                                            )
                                        })
                                    }
                                </Dropdown.Menu>
                            </Dropdown>}
                            {WIDTH > 575.98 &&<Nav.Link className="nav-btn">
                                <DarkModeSwitch
                                    checked={isDarkMode}
                                    onChange={toggleDarkMode}
                                    size={25}
                                />
                            </Nav.Link>}
                        </Navbar.Collapse>
                    </Navbar>
                </Container>
            </Container>
        </>
    );
}


const mapStateToProps = state => {
    return {
        APP_LOCALE: state.app.app_locale,
        IS_RTL: state.app.is_rtl,
        THEME: state.app.theme,
        DEVICE_COUNTRY: state.app.device_country
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setLocale: (data) => dispatch(setLocale(data)),
        setTheme: (data) => dispatch(setTheme(data)),
        setDeviceCountry: (data) => dispatch(setDeviceCountry(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
