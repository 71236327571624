import './ExpertCard.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft, faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { connect } from 'react-redux';

const Container = (props) => {
    if (props.slug) {
        return(
            <Link to={'/expert/'+props.slug} className="expert-card-container" style={props.style}>{props.children}</Link>
        );
    }
    return(
        <div className="expert-card-container" style={props.style}>{props.children}</div>
    );
}

function ExpertCard(props) {
    return (
        <Container {...props}>
            <div className="card-img-wrapper" style={{marginBottom: props.insideHeader === true ? '0px' : '20px'}}>
                <img src={props.image} alt={props.name}/>
                <div className="hover-overlay"></div>
            </div>
            {props.insideHeader ? 
                <div className="dark-overlay">
                    <h5 className="inside-header">{props.name}</h5>
                </div>
                :
                <div>
                    {props.name &&
                        <h5>{props.name}</h5>
                    }
                    {props.expertise &&
                        <label style={props.labelStyle}>
                            {props.expertise.length > 22 ? props.expertise.substr(0, 22)+'...' : props.expertise}
                        </label>
                    }
                    <div className="hover-icon-container" style={{marginTop: '5px'}}>
                        <FontAwesomeIcon className="hover-icon" icon={props.IS_RTL ? faLongArrowAltLeft : faLongArrowAltRight}/>
                    </div>
                </div>
            }
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        APP_LOCALE: state.app.app_locale,
        IS_RTL: state.app.is_rtl,
        THEME: state.app.theme
    }
}

export default connect(mapStateToProps, null)(ExpertCard);
