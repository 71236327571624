import React from 'react';
import './ArticleCard.scss';
import { ICON_CHEVRON_RIGHT } from './../../assets/images/Index'
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { THEME_COLORS } from './../../utils/Constant';

function ArticleCard(props) {
    return (
        <Link className="article-link" to={"/blog/"+props.slug}>
            <div className="article-card-container" style={{borderRadius: '10px', overflow: 'hidden'}}>
                <Row className="article-card-row">
                    <Col className="article-card-col" xl={props.first === true ? 9 : 12}>
                        <div style={{width: '100%', backgroundColor: THEME_COLORS?.[props.THEME].color.MAIN_BACKGROUND}}>
                            <img src={props.image} style={{ height: props.link === true ? '270px' : null }} className={`article-image ${props.first ? "first-image" : ""}`} alt={props.title}/>
                        </div>
                    </Col>
                    <Col className="article-card-col" xl={props.first === true ? 3 : 12}>
                        {props.link ? 
                            <div className="article-link-content-container">
                                <h6>
                                    {props.title}
                                </h6>
                                <img src={ICON_CHEVRON_RIGHT.default} alt={'icon-right<'}/>
                            </div>
                            :
                            <div className={`article-content-container ${props.first ? "first-content-container" : ""}`}>
                                <div>
                                    {props.tag > 0 &&<div className="article-tag">
                                        {props.tag}
                                    </div>}
                                    <h5>
                                        {props.title}
                                    </h5>
                                </div>
                                {props.first ?
                                    <div className="article-desc">
                                        {props?.excerpt}
                                    </div>
                                    :
                                    null
                                }
                                <div className="article-date">
                                    {props.date}
                                </div>
                            </div>
                        }
                    </Col>
                </Row>
            </div>
        </Link>
    )
}

const mapStateToProps = state => {
    return {
        APP_LOCALE: state.app.app_locale,
        IS_RTL: state.app.is_rtl,
        THEME: state.app.theme
    }
}

export default connect(mapStateToProps, null)(ArticleCard);