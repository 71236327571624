import '../../App.scss';
import './search.scss';
import './SearchResponsive.scss'
import React, { useState, useEffect } from 'react';
import { Container, InputGroup, FormControl, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { faSearch, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useHistory } from 'react-router-dom'; 
import queryString from 'query-string';
import { IMG_SEARCH_BG } from '../../assets/images/Index';
import { connect } from 'react-redux';
import ExpertCard from '../../components/ExpertCard/ExpertCard';
import FontIcon from '../../components/FontIcon/FontIcon';
import EventEmitter from './../../service/EventEmitter';
import { getExpertList, getCategoryDetail } from '../../service/Api/ApiRequest';
import { FeaturedExpertLoader, SearchCategoryDetailLoader } from '../../components/ContentLoader';
import { SearchNotFound, RoundedBtn } from '../../utils/UIComponent/UIComponent';

const _event = EventEmitter.getInstance();

function SearchCategoryDetail(props) {
    const { t } = useTranslation(['search']);
    const slug = props.match.params.slug;
    const history = useHistory();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const queryParamsPage = parseInt(queryParams?.page) || 1;
    const queryParamsKeyword = queryParams?.q || '';
    const [searchText, setSearchText] = useState(queryParamsKeyword);
    const [category, setCategory] = useState(null);
    const [loadingCategory, setLoadingCategory] = useState(true);
    const [experts, setExperts] = useState([]);
    const [loadingExperts, setLoadingExperts] = useState(true);
    const [hasPrevPage, setHasPrevPage] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(false);

    const _getExpertList =  (categoryId, page, keyword, countryCode) => {
        getExpertList('?limit=60&page='+page+'&keyword='+keyword+'&category='+categoryId+'&region='+countryCode).then((result) => {
            const resultParams = result?.data?.params || {};
            setExperts(result.data.data);
            setLoadingExperts(false);
            setHasPrevPage(resultParams?.has_prev_page);
            setHasNextPage(resultParams?.has_next_page);
        });
    }
    useEffect(() => {
        getCategoryDetail(slug).then((categoryResult) => {
            const categoryItem = categoryResult?.data?.data || {};
            setCategory(categoryItem);
            setLoadingCategory(false);
            _getExpertList(categoryItem.id, queryParamsPage, searchText, props.DEVICE_COUNTRY_CODE);
        });
        _event.on('app.region_change', (params) => {
            setLoadingExperts(true);
            getCategoryDetail(slug).then((categoryResult) => {
                const categoryItem = categoryResult?.data?.data || {};
                _getExpertList(categoryItem.id, queryParamsPage, searchText, params?.data?.country_code);
            });
        });
    }, [location]);
    const onUserSearch = (event) => {
        const text = event.currentTarget.value;
        setLoadingExperts(true);
        _getExpertList(category.id, queryParamsPage, text, props.DEVICE_COUNTRY_CODE);
    }
    const didPressPaginationNextBtn = () => {
        setLoadingExperts(true);
        const nextPage = queryParamsPage + 1;
        const queryParamsKeyword = queryParams?.q || '';
        history.push('/category/'+slug+'?page='+nextPage+'&q='+queryParamsKeyword);
    }
    const didPressPaginationPrevBtn = () => {
        setLoadingExperts(true);
        const prevPage = queryParamsPage - 1;
        const queryParamsKeyword = queryParams?.q || '';
        history.push('/category/'+slug+'?page='+prevPage+'&q='+queryParamsKeyword);
    }
    let paginationBtnAlignmentClass = 'pagination-btn-container';
    if (true === hasPrevPage && false === hasNextPage) {
        paginationBtnAlignmentClass += ' pagination-flex-start';
    }
    if (false === hasPrevPage && true === hasNextPage) {
        paginationBtnAlignmentClass += ' pagination-flex-end';
    }
    if (true === loadingExperts || true === loadingCategory) {
        return(
            <div className="searchpage-container">
                <Container fluid className="search-bg">
                    <div className="search-img-absolute-container">
                        <div style={{ height: '100%', width: '100%',position: 'relative'}}>
                        <img className="absolute-searcg-bg" src={IMG_SEARCH_BG.default} alt={''}/>
                        </div>
                    </div>
                    <Container className="search-container" style={{height: '330px'}}>
                        <div className="search-content-container">
                            <h2 style={{marginTop: '-100px'}}>{t('search:search_experts')}</h2>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="basic-addon1">
                                        <FontIcon className="search-icon" icon={faSearch}/>
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder={t('search:search_experts')}
                                    aria-label="Search experts"
                                    aria-describedby="basic-addon1"
                                    onChange={onUserSearch}
                                />
                            </InputGroup>
                        </div>
                    </Container>
                </Container>
                <SearchCategoryDetailLoader/>
            </div>
        )
    }
    return (
        <div className="searchpage-container category-detail-container">
            <Container fluid className="search-bg">
                <div className="search-img-absolute-container">
                    <div style={{ height: '100%', width: '100%',position: 'relative'}}>
                    <img className="absolute-searcg-bg" src={IMG_SEARCH_BG.default} alt={''}/>
                    </div>
                </div>
                <Container className="search-container">
                    <div className="search-content-container">
                        <h2>{t('search:search_experts')}</h2>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                    <FontIcon className="search-icon" icon={faSearch}/>
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder={t('search:search_experts')}
                                aria-label="Search experts"
                                aria-describedby="basic-addon1"
                                onChange={onUserSearch}
                            />
                        </InputGroup>
                    </div>
                </Container>
            </Container>
            <Container fluid className="category-section">
                {/* <WaveContainer 
                    fill={THEME_COLORS?.[props.THEME].wave.BACKGROUND}
                    paused={false}
                /> */}
                <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '65px', flexDirection: 'column'}}>
                    <div style={{width: '280px', height: '160px', marginTop: '-130px', backgroundColor: 'dark' === props?.THEME ? '#000' : '#fff', borderRadius: '12px', border: '2px solid '+('dark' === props?.THEME ? '#101010' : '#e8e8e8'), alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                        <img className="chevron-bottom" src={category.icon.url} style={{height: '75px'}} alt={''}/>
                    </div>
                    <div className="title-container" style={{textAlign: 'center', paddingLeft: '15px', paddingRight: '15px'}}>
                        <span className="title" style={{color: 'dark' === props?.THEME ? '#fff' : '#000', display: 'block', marginTop: '20px', fontSize: '28px', fontWeight: 800}}>{t('search:experts_in')} {category?.name?.[props?.APP_LOCALE]}</span>
                    </div>
                </div>
                <Container className="category-container">
                    {/* <div className="category-header-row" style={{marginTop: '20px'}}>
                        <div>
                            <h3>Experts</h3>
                        </div>
                    </div> */}
                    {experts.length <= 0 &&
                        <SearchNotFound/>
                    }
                    {false === loadingExperts ?
                        <>
                            <Row>
                                {experts.map((item, index) => (
                                    <Col md={3} lg={3} xl={2} xs={6} key={index.toString()} className="expert-card-parent">
                                        <ExpertCard slug={item.slug} image={item.profile_picture.base_url+item.profile_picture.files['300X300']} name={item?.name?.[props.APP_LOCALE]} expertise={item?.categories?.[props.APP_LOCALE]}/>
                                    </Col>
                                ))}
                            </Row>
                        </>
                    :
                        <div style={{marginBottom: '80px'}}>
                            <FeaturedExpertLoader/>
                        </div>
                    }
                    <div className={paginationBtnAlignmentClass}>
                        {true === hasPrevPage &&
                            <RoundedBtn icon={props.IS_RTL ? faChevronRight : faChevronLeft} prev onClick={didPressPaginationPrevBtn}/>
                        }
                        {true === hasNextPage &&
                            <RoundedBtn icon={props.IS_RTL ? faChevronLeft : faChevronRight} onClick={didPressPaginationNextBtn}/>
                        }
                        {/* {true == hasPrevPage &&
                            <a className="btn-container" href="" onClick={didPressPaginationPrevBtn}>
                                <div>
                                    <img className="golden-right-arrow" src={ICON_GOLDEN_LEFT_ARROW.default} />
                                </div>
                            </a>
                        }
                        {true == hasNextPage &&
                            <a className="btn-container" href="" onClick={didPressPaginationNextBtn}>
                                <div>
                                    <img className="golden-right-arrow" src={ICON_GOLDEN_RIGHT_ARROW.default} />
                                </div>
                            </a>
                        } */}
                    </div>
                </Container>
            </Container>
        </div>
    );
}


const mapStateToProps = state => {
    return {
        APP_LOCALE: state.app.app_locale,
        IS_RTL: state.app.is_rtl,
        THEME: state.app.theme,
        DEVICE_COUNTRY_CODE: state.app?.device_country?.country_code || 'kw'
    }
}

export default connect(mapStateToProps, null)(SearchCategoryDetail);