import './Session.scss'
import './SessionResponsive.scss'
import React, { useState, useEffect } from 'react';
import useForceUpdate from 'use-force-update';
import { useParams } from "react-router";
import { useHistory, useLocation } from 'react-router-dom'; 
import { DetailContainer } from '../expert-details/ExpertDetails';
import { ICON_TIMER, ICON_VIDEO, ICON_MAP_PIN, ICON_TIMER_LIGHT, ICON_VIDEO_LIGHT, ICON_MAP_PIN_LIGHT } from "./../../assets/images/Index";
import { Row, Col } from 'react-bootstrap';
import FontIcon from '../../components/FontIcon/FontIcon';
import SessionCard from '../../components/SessionCard/SessionCard';
import BeatLoader from "react-spinners/BeatLoader";
import { THEME_COLORS } from './../../utils/Constant';
import moment from 'moment';
import { connect } from 'react-redux';
import { faFacebookF, faYoutube, faInstagram, faTwitter, faLinkedinIn } from "@fortawesome/free-brands-svg-icons"
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { faChevronLeft, faChevronRight, faTimes, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { getSessionDetail, getSessionTimeslots } from './../../service/Api/ApiRequest';
import { useTranslation, Trans } from 'react-i18next';
import { Dialog, DialogContent } from '@material-ui/core';
import { DownloadPlatform } from '../../components/Footer/Footer';
import { SessionDetailLoader } from '../../components/ContentLoader';
// import { FacebookShareButton } from "react-share";

const SOCIAL_MEDIA_ICON_MAPPER = {
    instagram: faInstagram,
    facebook: faFacebookF,
    twitter: faTwitter,
    linkedin: faLinkedinIn
};

function Session(props) {
    const { slug } = useParams();
    const history = useHistory();
    const location = useLocation();
    const [session, setSession] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getSessionDetail(slug).then((result) => {
            setLoading(false);
            setSession(result.data.data);
        });
    }, [location]);
    if (null === session) {
        return(
            <SessionDetailLoader/>
        );
    }
    if (true === loading) {
        return(
            <SessionDetailLoader/>
        );
    }
    return (
        <div className="session-detail-container">
            <DetailContainer
                left={<SessionActionableCol session={session} history={history} {...props} />}
                right={<SessionDetailCol session={session} history={history} {...props} />}
                {...props}
            />
        </div>
    )
}


const SessionActionableCol = (props) => {
    const { t } = useTranslation(['common', 'session']);
    const { session } = props;
    const expertSocialMediaItems = session?.expert?.social_media || [];
    const languages = session?.expert?.languages || [];
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [loading, setLoading] = useState(true);
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [calendarDate, setCalendarDate] = useState(moment());
    const [timeslots, setTimeslots] = useState([]);
    const forceUpdate = useForceUpdate();
    const onDatePress = () => {
        setOpenDatePicker(true);
    }
    const closeDatePicker = () => {
        setOpenDatePicker(false);
    }
    const onDatePickerChange = (date) => {
        if (true === loading) {
            return;
        }
        const selectedDate = moment(date);
        setCalendarDate(selectedDate);
        setLoading(true);
        closeDatePicker();
        setTimeslots([]);
        forceUpdate();
        getSessionTimeslots({session_id: session.id, date: selectedDate.format('YYYY-MM-DD'), timezone: timezone}).then((result) => {
            setTimeslots(result.data.data);
            setLoading(false);
        });
    }
    const didPressNextDay = () => {
        if (true === loading) {
            return;
        }
        const date = calendarDate.add(1, 'days');
        setCalendarDate(date);
        setTimeslots([]);
        forceUpdate();
        setLoading(true);
        getSessionTimeslots({session_id: session.id, date: date.format('YYYY-MM-DD'), timezone: timezone}).then((result) => {
            setTimeslots(result.data.data);
            setLoading(false);
        });
    }
    const didPressPrevDay = () => {
        if (true === loading) {
            return;
        }
        const date = calendarDate.add(-1, 'days');
        setCalendarDate(date);
        setTimeslots([]);
        forceUpdate();
        setLoading(true);
        getSessionTimeslots({session_id: session.id, date: date.format('YYYY-MM-DD'), timezone: timezone}).then((result) => {
            setTimeslots(result.data.data);
            setLoading(false);
        });
    }
    useEffect(() => {
        getSessionTimeslots({session_id: session.id, date: calendarDate.format('YYYY-MM-DD'), timezone: timezone}).then((result) => {
            setTimeslots(result.data.data);
            setLoading(false);
        });
    }, []);
    return(
        <>
            <div className="session-expert-container">
                <h4>{t('session:session_expert')}</h4>
                <div className="expert-thumbnail-container">
                    <img
                        alt={session?.expert?.name?.[props?.APP_LOCALE]}
                        src={session.expert.profile_picture.base_url+session.expert.profile_picture.files['300X300']}
                    />
                    <div>
                        <h5>{session?.expert?.name?.[props?.APP_LOCALE]}</h5>
                        {/* <span className="expertise">{session?.expert?.name?.[props?.APP_LOCALE]}</span> */}
                    </div>
                </div>
            </div>
            {languages.length > 0 && 
                <div className="language-tag-container">
                    {languages.map((item,index)=> (
                        <LanguageTag key={index.toString()} name={item?.[props?.APP_LOCALE]}/>
                    ))}
                </div>
            }
            <h4>{t('session:availability')}</h4>
            <div className="availabilty-tabs-container">
                <div style={{display: 'none'}}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            disablePast={true}
                            open={openDatePicker}
                            onClose={closeDatePicker}
                            onChange={onDatePickerChange}
                        />
                    </MuiPickersUtilsProvider>
                </div>
                <div className="availabilty-tabs-content-container">
                    <div className="next-prev-btn" onClick={didPressPrevDay}>
                        <FontIcon icon={faChevronLeft}/>
                    </div>
                    <label onClick={onDatePress}>
                        {calendarDate.format('LL (dddd)')}
                    </label>
                    <div className="next-prev-btn" onClick={didPressNextDay}>
                        <FontIcon icon={faChevronRight}/>
                    </div>
                </div>
                <div className="time-slots-container">
                    {false === loading ?
                        <Row>
                            {timeslots.length > 0 ?
                                <>
                                    {timeslots.map((timeslot,index)=> {
                                        return(
                                            <Col key={index.toString()} lg={4} xs={3} className="time-slots-content-container">
                                                <div className="time-slot-pills">
                                                    {moment(timeslot.start_time_utc).format('LT')}
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </>
                            :
                                <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', height: '150px'}}>
                                    Not available
                                </div>
                            }
                        </Row>
                    :
                        <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                            <BeatLoader color={THEME_COLORS?.[props.THEME]?.color?.COUNTRY_NAV_LOADING_COLOR} loading={true} size={8} />
                        </div>
                    }
                </div>
            </div>
            <h4>{t('common:social')}</h4>
            <div className="social-media-container">
                {Object.keys(expertSocialMediaItems).map((key, i) => {
                    const expertSocialMediaItem = expertSocialMediaItems?.[key] ? expertSocialMediaItems[key].trim() : '';
                    if (typeof expertSocialMediaItem !== 'string') {
                        return;
                    }
                    if (expertSocialMediaItem.length === 0) {
                        return;
                    }
                    let url;
                    if ('linkedin' === key) {
                        url = 'https://www.linkedin.com/company/'+expertSocialMediaItem;
                    } else {
                        url = 'https://www.'+key+'.com/'+expertSocialMediaItem;
                    }
                    return(
                        <div key={i.toString()} className="social-media" onClick={() => window.open(url, "_blank")}>
                            <FontIcon icon={SOCIAL_MEDIA_ICON_MAPPER[key]} />
                        </div>
                    );
                })}
            </div>
        </>
    )
}

const SessionDetailCol = (props) => {
    const { t } = useTranslation(['common', 'session']);
    const { session } = props;
    const videoSessionPrice = parseFloat(session?.price);
    const walkinPrice = parseFloat(session?.walk_in_price);
    const expertSessions = session?.expert?.sessions || [];
    const didPressSessionCard = (item) => {
        props.history.push('/session/'+item.slug);
    }
    const [open, setOpen] = useState(false);
    const didPressExpert = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    };
    return(
        <>
            <div className="session-info-container">
            <img alt={session?.title?.[props?.APP_LOCALE]} className="session-image" src={session.cover_picture.base_url+session.cover_picture.files['700X400']}/>
            <h1>{session?.title?.[props?.APP_LOCALE]}</h1>
            <p>{session?.description?.[props?.APP_LOCALE]}</p>
            <div className="timer-container">
                <img className="timer-icon" alt={'session duration'} src={ICON_TIMER.default}/>
                <img className="timer-icon-light"  alt={'session duration'} src={ICON_TIMER_LIGHT.default}/>
                <span className="duration">{session.duration_in_minutes} {t('session:min')}</span>
            </div>
            <div className="book-now-container">
                <div className="price-container">
                    {true === session?.enable_video_booking &&
                        <div className="price-content-container">
                            <img className="video-icon" alt="Video session price" src={ICON_VIDEO.default}/>
                            <img className="video-icon-light" alt="Video session price" src={ICON_VIDEO_LIGHT.default}/>
                            <span className="price">{0 === videoSessionPrice ? t('free') : videoSessionPrice + ' ' + t('common:kd')}</span>
                        </div>
                    }
                    {true === session?.enable_walk_in_booking &&
                        <div className="price-content-container">
                            <img className="map-pin" alt="Wallk-in session price" src={ICON_MAP_PIN.default}/>
                            <img className="map-pin-light" alt="Wallk-in session price" src={ICON_MAP_PIN_LIGHT.default}/>
                            <span className="price">{0 === walkinPrice ? t('free') : walkinPrice + ' ' + t('kd')}</span>
                        </div>
                    }
                </div>
                <div onClick={didPressExpert} style={{cursor: 'pointer'}}>
                    <div className="btn-container">
                        <div>
                            {t('session:book_now')}
                        </div>
                    </div>
                </div>
            </div>
            <div className="more-sessions-container">
                <h5>{t('session:all_sessions_offered_by_this_expert')}</h5>
                <Row>
                    {expertSessions.map((item)=> (
                        <Col key={item.id} lg={6} className="session-card-wrapper">
                            <div style={{cursor: 'pointer'}} onClick={() => didPressSessionCard(item)}>
                                <SessionCard image={item.cover_picture.base_url+item.cover_picture.files['700X400']} title={item?.title?.[props?.APP_LOCALE]}/>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
        <Dialog className="download-modal" onClose={handleClose} open={open}>
            <DialogContent dividers>
                <div className="close-btn-container" onClick={handleClose}>
                    <FontIcon icon={faTimes}/>
                </div>
                <div>
                    <h2>
                        <Trans i18nKey="common:download_dialog.download_and_register"/>
                    </h2>
                    <p>
                        <Trans i18nKey="common:download_dialog.available_for_download"/>
                    </p>
                    <DownloadPlatform contact={true}/>
                </div>
            </DialogContent>
        </Dialog>
    </>
    )
}

export const LanguageTag = (props) => {
    return(
        <div className="language-tag">
            <div style={{display: 'inline-flex'}}>
                {/* <img alt="Expert Languages" src={ICON_CHECK.default}/> */}
                <FontIcon className="language-icon" icon={faCheckCircle}/>
            </div>
            <div style={{display: 'inline-flex'}}>
                {props.name}
            </div>
        </div>
    )
}

export const SocialMedia = (props) => {
    const SOCIAL_MEDIA = [
        {
            icon: faLinkedinIn,
            name: 'linkedin',
            url: 'https://www.linkedin.com/company/walsos-app'
        },
        {
            icon: faYoutube,
            name: 'youtube',
            url: 'https://www.youtube.com/channel/UCRCpu-s6dl-a-OZtW4KEefw'
        },
        {
            icon: faInstagram,
            name: 'instagram',
            url: 'https://www.instagram.com/walsos_app/?hl=en'
        },
        {
            icon: faFacebookF,
            name: 'facebook',
            url: 'https://www.facebook.com/walsosapp'
        },
    ]
    return(
        <div className="social-media-container">
            {SOCIAL_MEDIA.map((item)=> {
                return(
                    <div key={item.name} className="social-media" onClick={() => props.onClick ? props.onClick(item) : null}>
                        <FontIcon icon={item.icon} />
                    </div>
                );
            })}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        IS_RTL: state.app.is_rtl,
        APP_LOCALE: state.app.app_locale,
        THEME: state.app.theme
    }
}

export default connect(mapStateToProps, null)(Session);