import React from 'react'
import './UIComponent.scss'

import { IMG_SEARCH_NOT_FOUND } from '../../assets/images/Index';
import FontIcon from '../../components/FontIcon/FontIcon';
import { useTranslation } from 'react-i18next';

export const SearchNotFound = () => {
    const { t } = useTranslation(['common']);
    return(
        <div className="search-not-found-container">
            <img src={IMG_SEARCH_NOT_FOUND.default} alt="search-not-found"/>
            <div>
                {t('common:no_results_found')}
            </div>
        </div>
    )
}

export const RoundedBtn = (props) => {
    const { t } = useTranslation(['common']);
    return(
        <div className="direction-btn-container" onClick={props.onClick}>
            <div>
                {props.prev ? 
                    <>
                        <FontIcon icon={props.icon} className="prev-icon"/>
                        {t('common:previous')}
                    </>
                :
                    <>
                        {t('common:next')}
                        <FontIcon icon={props.icon}/>
                    </>
                }
            </div>
        </div>
    )
}
