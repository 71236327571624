import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#dddddd'
        }
    }
});
