import React from 'react';
import './SessionCard.scss'

export default function SessionCard(props) {
    return (
        <div className="session-card-container">
            <div className="session-card-overlay-effect"></div>
            <div className="card-img-wrapper">
                <img src={props.image} alt={props.title}/>
                <div className="hover-overlay"></div>
            </div>
            <div className="session-card-overlay">
                <h4>{props.title}</h4>
            </div>
        </div>
    )
}
