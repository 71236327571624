import * as API  from './ApiConstant';
import axios from 'axios';

export function getCategoryList(queryParams = '') {
    return axios.get(API.API_CATEGORY_LIST+queryParams);
}
export function getCategoryDetail(slug) {
    return axios.get(API.API_CATEGORY_DETAIL.replace(':slug', slug));
}
export function getFeaturedExperts(queryParams = '') {
    return axios.get(API.API_FEATURED_EXPERT+queryParams);
}
export function getExpertProfile(slug) {
    return axios.get(API.API_EXPERT_PROFILE.replace(':slug', slug));
}
export function getFeaturedBanners(queryParams = '') {
    return axios.get(API.API_FEATURED_BANNERS+queryParams);
}
export function getSessionList(queryParams = '') {
    return axios.get(API.API_SESSION_LIST+queryParams);
}
export function getArticles(queryParams = '') {
    return axios.get(API.API_ARTICLE+queryParams);
}
export function getTestimonials(queryParams = '') {
    return axios.get(API.API_TESTIMONIAL+queryParams);
}
export function getSessionDetail(slug) {
    return axios.get(API.API_SESSION_DETAIL.replace(':slug', slug));
}
export function getSessionTimeslots(queryParams) {
    return axios.get(API.API_SESSION_TIMESLOTS+'?session_id='+queryParams.session_id+'&date='+queryParams.date+'&timezone='+queryParams.timezone);
}
export function getVideos(queryParams = '') {
    return axios.get(API.API_VIDEO+queryParams);
}
export function ping() {
    return axios.get(API.API_APP_PING);
}
export function getRegionList(queryParams = '') {
    return axios.get(API.API_REGION_LIST+queryParams);
}
export function contactMessage(formData) {
    return axios.post(API.API_USER_CONTACT_MESSAGE, formData);
}
export function termsAndConditions() {
    return axios.get(API.API_APP_TERMS_AND_CONDITIONS);
}
export function privacyPolicy() {
    return axios.get(API.API_APP_PRIVACY_POLICY);
}
export function getFaqList(queryParams = '') {
    return axios.get(API.API_APP_FAQ_LIST+queryParams);
}
export function getExpertList(queryParams = '') {
    return axios.get(API.API_EXPERT_LIST+queryParams);
}
export function syncDeviceInfo(formData) {
    return axios.post(API.API_USER_DEVICE_INFO, formData);
}
export function multipleHttpRequest(requests = [], successCallback, errorCallback) {
    axios.all(requests).then(axios.spread((...responses) => {
        successCallback(responses);
    })).catch(errors => {
        errorCallback(errors);
    });
}