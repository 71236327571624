import '../../App.scss';
import './search.scss';
import React, { useState, useEffect } from 'react';
import { Container, InputGroup, FormControl, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useHistory } from 'react-router-dom'; 
import queryString from 'query-string';
import { IMG_SEARCH_BG } from '../../assets/images/Index';
import { connect } from 'react-redux';
import CategoryCard from '../../components/CategoryCard/CategoryCard';
import FontIcon from '../../components/FontIcon/FontIcon';
import { getCategoryList } from '../../service/Api/ApiRequest';
import { SearchCategoryLoader } from '../../components/ContentLoader';
import { ICON_GOLDEN_RIGHT_ARROW, ICON_GOLDEN_LEFT_ARROW } from './../../assets/images/Index';
import { SearchNotFound } from '../../utils/UIComponent/UIComponent';

function SearchCategory(props) {
    const history = useHistory();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const queryParamsPage = parseInt(queryParams?.page) || 1;
    const queryParamsKeyword = queryParams?.q || '';
    const { t } = useTranslation(['search']);
    const [categories, setCategories] = useState([]);
    const [loadingCategories, setLoadingCategories] = useState(true);
    const [hasPrevPage, setHasPrevPage] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(false);
    useEffect(() => {
        getCategoryList('?limit=1000&keyword='+queryParamsKeyword).then((result) => {
            const resultParams = result?.data?.params || {};
            setCategories(result.data.data);
            setLoadingCategories(false);
            setHasPrevPage(resultParams?.has_prev_page);
            setHasNextPage(resultParams?.has_next_page);
        });
    }, []);
    const didPressPaginationNextBtn = () => {
        setLoadingCategories(true);
        const nextPage = queryParamsPage + 1;
        const queryParamsKeyword = queryParams?.q || '';
        history.push("/search/categories?page="+nextPage+'&q='+queryParamsKeyword);
    }
    const didPressPaginationPrevBtn = () => {
        setLoadingCategories(true);
        const prevPage = queryParamsPage - 1;
        const queryParamsKeyword = queryParams?.q || '';
        history.push("/search/categories?page="+prevPage+'&q='+queryParamsKeyword);
    }
    let paginationBtnAlignmentClass = 'pagination-btn-container';
    if (true === hasPrevPage && false === hasNextPage) {
        paginationBtnAlignmentClass += ' pagination-flex-start';
    }
    if (false === hasPrevPage && true === hasNextPage) {
        paginationBtnAlignmentClass += ' pagination-flex-end';
    }
    const onUserSearch = (event) => {
        const text = event.currentTarget.value;
        setLoadingCategories(true);
        getCategoryList('?limit=1000&keyword='+text).then((result) => {
            const resultParams = result?.data?.params || {};
            setCategories(result.data.data);
            setLoadingCategories(false);
            setHasPrevPage(resultParams?.has_prev_page);
            setHasNextPage(resultParams?.has_next_page);
        });
    }
    const didPressCategoryItem = (item) => {
        history.push("/category/"+item.slug);
    }
    return (
        <div className="searchpage-container search-category-container">
            <Container fluid className="search-bg">
                <div className="search-img-absolute-container">
                    <div style={{ height: '100%', width: '100%',position: 'relative'}}>
                    <img className="absolute-searcg-bg" src={IMG_SEARCH_BG.default} alt={''}/>
                    </div>
                </div>
                <Container className="search-container">
                    <div className="search-content-container">
                        <h2>{t('search:search_categories')}</h2>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                    <FontIcon className="search-icon" icon={faSearch}/>
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder={t('search:search_categories')}
                                aria-label="Search Categories"
                                aria-describedby="basic-addon1"
                                onChange={onUserSearch}
                            />
                        </InputGroup>
                    </div>
                </Container>
            </Container>
            <Container fluid className="category-section">
                <Container className="category-container">
                    {(categories.length === 0 && false === loadingCategories) &&
                        <SearchNotFound/>
                    }
                    {false === loadingCategories ?
                        <>
                            <Row className="justify-content-center">
                                {categories.map((item, index)=> (
                                    <Col key={index.toString()} md={'auto'} xs={4} className="category-card-col-container">
                                        <CategoryCard
                                            key={item.id.toString()}
                                            title={item?.name?.[props.APP_LOCALE]}
                                            image={item.icon.thumb}
                                            onClick={() => didPressCategoryItem(item)}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </>
                    :
                        <div style={{marginBottom: '80px'}}>
                            {/* <CategoryFilterLoader/> */}
                            <SearchCategoryLoader/>
                        </div>
                    }
                    <div className={paginationBtnAlignmentClass}>
                        {true === hasPrevPage &&
                            <div className="btn-container" onClick={didPressPaginationPrevBtn}>
                                <div>
                                    <img className="golden-right-arrow" src={ICON_GOLDEN_LEFT_ARROW.default} alt={'left-arrow'}/>
                                </div>
                            </div>
                        }
                        {true === hasNextPage &&
                            <div className="btn-container" onClick={didPressPaginationNextBtn}>
                                <div>
                                    <img className="golden-right-arrow" src={ICON_GOLDEN_RIGHT_ARROW.default} alt={'right-arrow'}/>
                                </div>
                            </div>
                        }
                    </div>
                </Container>
            </Container>
        </div>
    );
}


const mapStateToProps = state => {
    return {
        APP_LOCALE: state.app.app_locale,
        IS_RTL: state.app.is_rtl,
        THEME: state.app.theme
    }
}

export default connect(mapStateToProps, null)(SearchCategory);