import {
    SET_LOCALE,
    SET_DEVICE_COUNTRY,
    SET_THEME,
    DEVICE_SYNCED
} from './../actions/types';

const INITIAL_STATE = {
    app_locale: null,
    is_rtl: null,
    device_country: null,
    theme: 'dark',
    device_synced: null
};

export function app(state = INITIAL_STATE, action){
    switch (action.type) {
        case SET_LOCALE:
            return {
                ...state,
                app_locale: action.payload,
                is_rtl: 'ar' === action.payload
            }
        case SET_DEVICE_COUNTRY:
            return {
                ...state,
                device_country: action.payload
            }
        case SET_THEME:
            return {
                ...state,
                theme: action.payload
            }
        case DEVICE_SYNCED:
            return {
                ...state,
                device_synced: action.payload
            }
    }
    return state;
}
