export const API_BASE_URL = 'https://cpanel.walsos.com';
// export const API_BASE_URL = 'https://staging.cpanel.walsos.com';
// export const API_BASE_URL = 'http://walsos-api.local';
export const API_ENDPOINT = API_BASE_URL+'/api/v1';
export const BASE_URL = 'https://walsos.com/';
export const TERMS_URL = BASE_URL+'%locale/terms-and-conditions?disable-header=true'
export const PRIVACY_URL = BASE_URL+'%locale/privacy-policy?disable-header=true'
export const APP_DOWNLOAD_URL = 'https://walsos.com/en/download-app';
// API Resources
export const API_FEATURED_EXPERT = API_ENDPOINT + '/user/expert/featured';
export const API_FEATURED_BANNERS = API_ENDPOINT + '/banner/featured';
export const API_CATEGORY_LIST = API_ENDPOINT + '/category/list';
export const API_CATEGORY_DETAIL = API_ENDPOINT + '/category/:slug/summary';
export const API_SESSION_LIST = API_ENDPOINT + '/session/list';
export const API_EXPERT_PROFILE = API_ENDPOINT + '/user/expert/profile/:slug';
export const API_ARTICLE = API_ENDPOINT + '/article';
export const API_TESTIMONIAL = API_ENDPOINT + '/testimonial';
export const API_SESSION_DETAIL = API_ENDPOINT + '/session/detail/:slug';
export const API_SESSION_TIMESLOTS = API_ENDPOINT + '/session/timeslots';
export const API_VIDEO = API_ENDPOINT + '/video';
export const API_APP_PING = API_ENDPOINT + '/app/ping';
export const API_REGION_LIST = API_ENDPOINT + '/region/list';
export const API_USER_CONTACT_MESSAGE = API_ENDPOINT + '/user/contact-message';
export const API_APP_TERMS_AND_CONDITIONS = API_ENDPOINT + '/app/terms-and-conditions';
export const API_APP_PRIVACY_POLICY = API_ENDPOINT + '/app/privacy-policy';
export const API_APP_FAQ_LIST = API_ENDPOINT + '/app/faq-list';
export const API_EXPERT_LIST = API_ENDPOINT + '/user/expert/list';
export const API_USER_DEVICE_INFO = API_ENDPOINT + '/user/device-info';