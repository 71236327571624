import React, { useState, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { IMG_CONFETTI, IMG_FEATURES_MOBILE, ICON_LIST_BOARD, ICON_LIST_BOARD_2, ICON_LIST_BOARD_2_LIGHT, ICON_VIDEO_CAM, ICON_VIDEO_CAM_LIGHT, ICON_PRIVATE_SHIELD, ICON_NOTIFICATION_ALERT, ICON_PLAY_VIDEO, ICON_INPERSON, ICON_TIMER_CLOCK, ICON_TIMER_CLOCK_LIGHT } from './../../assets/images/Index'
import { AboutWalsosSection, TestimonialSection } from '../Home/Home';
import { THEME_COLORS } from './../../utils/Constant';
import ExpertCard from '../../components/ExpertCard/ExpertCard';
import WaveContainer from '../../components/wave/WaveContainer';
import './ForExperts.scss'
import './ForExpertsResponsive.scss'
import FontIcon from '../../components/FontIcon/FontIcon';
import { faLongArrowAltLeft, faLongArrowAltRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Dialog, DialogContent } from '@material-ui/core';
import { DownloadPlatform } from '../../components/Footer/Footer';

const WIDTH = window.innerWidth;
const HEIGHT = window.innerHeight;
const CARDWIDTH = 170;
const CARDHEIGHT = 278;
const SPACING = 50;

function ForExperts(props) {
    const { t } = useTranslation(['for_expert']);
    const [open, setOpen] = useState(false);
    const didPressExpert = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="for-experts-container">
                <Container fluid className="header-banner-container">
                    <Container className="header-banner-content-container">
                        <h1>
                            <Trans i18nKey="for_expert:page_title"/>
                        </h1>
                        <p>
                            <Trans i18nKey="for_expert:page_info"/>
                        </p>
                        {/* <Btn title={t('for_expert:register_as_an_expert')}/> */}
                        <div className="btn-container" onClick={didPressExpert}>
                            <div>
                                {t('for_expert:register_as_an_expert')}
                            </div>
                        </div>
                        {EXPERTS.map((item, index)=> (
                            <div key={index.toString()} className={`card-wrapper ${'card'+(index+1)}`} style={{left: item.left, right: item.right, bottom: item.bottom, top: item.top}}>
                                <div className="expert-card-wrapper">
                                    <ExpertCard image={item.image} />
                                </div>
                            </div>
                        ))}
                        <div className="underlay-forexperts-container">
                            <img src={IMG_CONFETTI.default} alt="confetti"/>
                        </div>
                    </Container>
                </Container>
                <FeaturesSection {...props}/>
                <RegistrationBenefitSection {...props}/>
                <AboutWalsosSection wavecolor={THEME_COLORS?.[props.THEME].wave.MAIN_BACKGROUND} bgcolor={THEME_COLORS?.[props.THEME].color.MAIN_BACKGROUND} {...props}/>
                <TestimonialSection {...props}/>
            </div>
            <Dialog className="download-modal" onClose={handleClose} open={open}>
                <DialogContent dividers>
                    <div className="close-btn-container" onClick={handleClose}>
                        <FontIcon icon={faTimes}/>
                    </div>
                    <div>
                        <h2>
                            <Trans i18nKey="common:download_dialog.download_and_register"/>
                        </h2>
                        <p>
                            <Trans i18nKey="common:download_dialog.available_for_download"/>
                        </p>
                        <DownloadPlatform contact={true}/>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

const FeaturesSection = (props) => {
    const { t } = useTranslation(['for_expert']);
    return(
        <Wrapper className="features-section">
            <WaveContainer 
                fill={THEME_COLORS?.[props.THEME].wave.MAIN_BACKGROUND}
                paused={false}
            />
            <Row className="features-row">
                <Col md={4} className="features-list">
                    <ul>
                        {FEATURES_LIST.map((item, index)=> (
                            <li key={index.toString()}>
                                {t(item.title)}<span className="advantages-list-icon">
                                    <img src={item.img} alt={t(item.title)}/>	
                                </span>
                            </li>
                        ))}
                    </ul>
                </Col>
                <Col md={4} className="features-mob-container"><img src={IMG_FEATURES_MOBILE.default} alt="mobile-hardware"/></Col>
                <Col md={4} className="features-list">
                    <ul>
                        {FEATURES_LIST_TWO.map((item,index)=> (
                            <li key={index.toString()}>
                                {t(item.title)}<span className="features-list-icon">
                                    <img src={item.img} alt={t(item.title)}/>	
                                </span>
                            </li>
                        ))}
                    </ul>
                </Col>
            </Row>
        </Wrapper>
    )
}

const RegistrationBenefitSection = (props) => {
    
    const { t } = useTranslation(['for_expert']);
    return(
        <Wrapper title={t('for_expert:register_in_3_simple_steps')} className="register-benefits-section">
            <WaveContainer 
                fill={THEME_COLORS?.[props.THEME].wave.BACKGROUND}
                paused={false}
            />
            <Row>
                {REGISTRATION_BENEFITS.map((item, index)=> {
                    return(
                        <Fragment key={index.toString()}>
                            {
                                index === 1 &&
                                <div style={{display: WIDTH > 575.98 ? 'flex' : 'none', alignItems: 'center', fontSize: '20px'}}>
                                    <FontIcon icon={props.IS_RTL ? faLongArrowAltLeft : faLongArrowAltRight} className="steps-arrow"/>
                                </div>
                            }
                            {
                                index === 2 && 
                                <div style={{display: WIDTH > 575.98 ? 'flex' : 'none', alignItems: 'center', fontSize: '20px'}}>
                                    <FontIcon icon={props.IS_RTL ? faLongArrowAltLeft : faLongArrowAltRight} className="steps-arrow"/>
                                </div>
                            }
                            <Col key={index.toString()} className="benefits-col-container">
                                <img src={item.icon[props?.THEME]} alt={t(item.title)}/>
                                {/* <h6>{t('for_expert:register_as_an_expert')}</h6> */}
                                <h6>{t(item.title)}</h6>
                                {/* <p>{t('for_expert:consult_with_any_expert_from_a_wide_range_of_fields_through_video')}</p> */}
                            </Col>
                        </Fragment>
                    )
                })}
            </Row>
        </Wrapper>
    )
}

const Wrapper = (params={}) => {
    return(
        <Container fluid style={{paddingTop: 0, paddingBottom: 270, position: 'relative' }} className={`wrapper-container ${ params.className }`}>
            <Container>
                {params.title &&
                    <h5>{params.title}</h5>
                }
                {params.children}
            </Container>
        </Container>
    )
}

const FEATURES_LIST = [
	{
		title: 'define_your_availability',
		img: ICON_LIST_BOARD.default
	},
	{
		title: 'multilingual',
		img: ICON_VIDEO_CAM.default
	},
	{
		title: 'private_secure',
		img: ICON_PRIVATE_SHIELD.default
	},
	{
		title: 'expert_profile',
		img: ICON_PLAY_VIDEO.default
	},
]
const FEATURES_LIST_TWO = [
	{
		title: 'in_person_meeting',
		img: ICON_INPERSON.default
	},
	{
		title: 'video_audio_chat',
		img: ICON_PLAY_VIDEO.default
	},
	{
		title: 'global_audience',
		img: ICON_TIMER_CLOCK.default
	},
	{
		title: 'notifications_alerts',
		img: ICON_NOTIFICATION_ALERT.default
	},
]
const EXPERTS = [
    {
        image: 'https://walsos.sfo2.digitaloceanspaces.com/2020-07/IXAooKp4SWaFhTG8DOH6TNmNs1aB1eyGIxHcqsL9DfDs7NTKxdjAJPSIOYpyCty5rdgxnLbjCUbpHt2CK_300X300.jpeg',
        name: 'Mohammed Baqer',
        left: 0,
        top: (HEIGHT/2) - SPACING*2
        },
    {
        image: 'https://walsos.sfo2.digitaloceanspaces.com/2020-02/3dXkX0uGbrcUsCg2avHnrGE6R9xIzXtSTEw8DLjp3HJcJac3uKmy25ikqrKtw7_300X300.jpeg',
        name: 'Dr. Ahmed Nabeel',
        top: HEIGHT*0-(SPACING*3),
        left: 300
    },
    {
        image: 'https://walsos.sfo2.digitaloceanspaces.com/2020-03/oYOBhqNwUdspAR5kAB41MQxn43nDp0IFUCxjUOzMsKouZISUxhmvzzYKU8PP_300X300.jpeg',
        name: 'Abdulmohsen',
        right: 0,
        top: SPACING
    },
    {
        image: 'https://walsos.sfo2.digitaloceanspaces.com/2019-04/NprxWx1MENnRNmNwyic9dzPZ3NJcdXTvwLaYijjPCVFjKAeFD6t42cvNdZgp8PUVVwTnRNZC6i0AU2QGuuN1ojqyRl8T_300X300.jpeg',
        name: 'Ahmed Al Mulla',
        right: WIDTH*0+CARDWIDTH - SPACING,
        top: HEIGHT-CARDHEIGHT*1.4
    },
]
const REGISTRATION_BENEFITS = [
    {
        icon: {
            light: ICON_LIST_BOARD_2_LIGHT.default,
            dark: ICON_LIST_BOARD_2.default
        },
        title: 'for_expert:register_as_an_expert'
    },
    {
        icon: {
            light: ICON_VIDEO_CAM_LIGHT.default,
            dark: ICON_VIDEO_CAM.default
        },
        title: 'for_expert:create_your_first_session'
    },
    {
        icon: {
            light: ICON_TIMER_CLOCK_LIGHT.default,
            dark: ICON_TIMER_CLOCK.default,
        },
        title: 'for_expert:define_your_availability'
    },
]

const mapStateToProps = state => {
    return {
        APP_LOCALE: state.app.app_locale,
        IS_RTL: state.app.is_rtl,
        THEME: state.app.theme
    }
}

export default connect(mapStateToProps, null)(ForExperts);